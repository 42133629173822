import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { useQueryClient, useQuery } from "react-query";
import { fetchProduct, deleteProduct } from "../../../api/product";
import {
  NavContainer,
  IconLinkContainer,
  ImageAndVideoContainer,
  ImageContainer,
  ProductImage,
  VideoContainer,
  ProductText,
} from "./ProductDetails.styled";
import { BackLink } from "../../BackLink/BackLink";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import css from "./ProductDetails.module.css";
import { PrimaryTitle } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  IconButton,
  TrashIconButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { ScrollToTopButton } from "../../ScrollToTopButton/ScrollToTopButton";

export const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();  
  const [product, setProduct] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isPrimary, setIsPrimary] = useState(false);
  const [isList, setIsList] = useState(false);
  const path = location?.pathname;

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");
      if (parts?.length >= 3) {
        const getId = parts[3];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkPrimary = `/${parts[2]}` === "/primary-list";
        const checkList = `/${parts[2]}` === "/list";

        if (checkPrimary) {
          setIsPrimary(true);
        }

        if (checkList) {
          setIsList(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    if (!selectedId) return;

    const cachedProductData = queryClient.getQueryData(["productData"]);
    if (cachedProductData) {
      const cachedProduct = cachedProductData.data.find((item) => item.id === selectedId);
      if (cachedProduct) {
        setProduct(cachedProduct);
        return;
      }
    }

    const fetchProductData = async () => {
      const response = await fetchProduct({ id: selectedId });
      if (response?.data?.length > 0) {
        const fetchedData = response?.data[0];
        setProduct(fetchedData);
      }
    };

    fetchProductData();
  }, [selectedId, queryClient]);

  const handleDelete = async (id) => {
    const response = await deleteProduct(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return navigate("/product/primary-list");
    }
  };

  return (
    <div>
      <Main>
        <NavContainer>
          {isList && <BackLink to="/product/list" />}
          {isPrimary && <BackLink to="/product/primary-list" />}

          {isPrimary && (
            <IconLinkContainer style={{ marginLeft: "20px" }}>
              <IconButton to={`/product/change/${product?.id}`}>
                <FaEdit size={"24px"} />
              </IconButton>
              <TrashIconButton onClick={() => handleDelete(product?.id)}>
                <FaTrash size={"24px"} />
              </TrashIconButton>
            </IconLinkContainer>
          )}
        </NavContainer>
        {product && (
          <div style={{ userSelect: "none" }}>
            <PrimaryTitle>{product.title}</PrimaryTitle>
            <ImageAndVideoContainer>
              <ImageContainer>
                <ProductImage src={product.image_url} alt="фото товару" />
              </ImageContainer>
              <VideoContainer>
                <iframe
                  width="560"
                  height="560"
                  src={product.video_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </VideoContainer>
            </ImageAndVideoContainer>
            <PrimaryTitle>Опис товару</PrimaryTitle>
            <ProductText><span style={{color: 'red', textDecoration: ''}}>Ціна без знижки: {((product.price + 500) * 1.71)?.toFixed()} грн</span></ProductText>
            <ProductText style={{color: 'green'}}>Ціна зі знижкою: {Number(product.price) + 500} грн</ProductText>
            <div
              style={{ userSelect: "none" }}
              className={css.productContent}
              dangerouslySetInnerHTML={{
                __html: product?.description,
              }}
            />
          </div>
        )}
        <ScrollToTopButton />
      </Main>
    </div>
  );
};
