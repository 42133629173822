import { Main } from "../../GlobalComponentsAndStyles/Main";
import React, { useState, useEffect } from "react";
import { Modal } from "../../Modal/Modal";
import {
  ScheduleTable,
  ScheduleTr,
  ScheduleTh,
  ScheduleTd,
  SaveButton,
  AddedTimeContainer,
  AddedTimeInput,
  ScheduleMeNavContainer,
  AddScheduleContainerModal,
} from "./ScheduleAllEmployees.styled";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IconButton,
  TrashIconButton,
  Button,
  TrashButton,
  ButtonContainer,
  Thead,
  FilterSelect,
  FilterOption,
  FlexContainer,
  StickyTd
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  deleteScheduleByDay,
  updateSchedule,
  createScedule,
  fetchAllSchedule,
} from "../../../api/schedule";
import { FaTrash } from "react-icons/fa";
import { MdOutlineAddCircle } from "react-icons/md";
import { addNewAmountForSchedule } from "../../../api/balance";
import { fetchPosition } from "../../../api/settings";
import { calculateHoursDifference } from "../../../helpers/calculateHoursDifference";
import { getWeekRanges } from "../../../helpers/getWeekRanges";
import { getColorByHoursMonth } from "../../../helpers/gradient/getColorByHoursMonth";
import { getColorByHoursWeek } from "../../../helpers/gradient/getColorByHoursWeek";
import { formatToTime } from "../../../helpers/dateAndTime/formatToTime";

export const ScheduleAllEmployees = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1)?.getDay();
  const [selectedStartDate, setSelectedStartDate] = useState(1);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [filteredDays, setFilteredDays] = useState([]);
  const [isBlocked, setIsBlocked] = useState(false);
  const [jobTitle, setJobTitle] = useState("eab7c78f-1559-4bed-8a2a-67d97240f6c6");
  const [initialData, setInitialData] = useState({
    id: "",
    user_id: "",
    day: "",
    working_time: [],
    is_approve: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const { data, refetch } = useQuery(
    ["schedule", currentYear, currentMonth, isBlocked, jobTitle],
    () =>
      fetchAllSchedule({
        year: currentYear,
        month: currentMonth,
        isBlocked,
        jobTitle,
      })
  );

  const { data: positionData } = useQuery("position", fetchPosition);

  const handleCancelEdit = () => {
    resetValue();
    setIsUpdate(false);
    setModalOpen(false);
  };

  const resetValue = () => {
    setInitialData({
      id: "",
      user_id: "",
      day: "",
      working_time: [],
      is_approve: "",
    });
  };

  const changeMonth = (direction) => {
    if (direction === 1 && currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear((prev) => prev + 1);
    } else if (direction === -1 && currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear((prev) => prev - 1);
    } else {
      setCurrentMonth((prev) => prev + direction);
    }
  };

  const handleAddWorkingTime = (arrayName, defaultItem) => {
    setInitialData({
      ...initialData,
      [arrayName]: [...initialData[arrayName], { ...defaultItem }],
    });
  };

  const handleRemoveWorkingTime = (indexToRemove) => {
    setInitialData({
      ...initialData,
      working_time: initialData?.working_time?.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const handleArrayInputChange = (arrayName, index, field, value) => {
    const updatedArray = [...initialData[arrayName]];
    updatedArray[index][field] = value;

    setInitialData({
      ...initialData,
      [arrayName]: updatedArray,
    });
  };

  const handleUpdate = async (isPayStatus) => {
    const newDate = new Date(currentYear, currentMonth - 1, selectedDay + 1);
    const formattedDate = newDate.toISOString().split("T")[0];

    const newData = {
      ...initialData,
      day: formattedDate,
      is_approve: isPayStatus ? "true" : initialData.is_approve,
    };

    if (!isUpdate) {
      const response = await createScedule(newData);

      if (response?.status === 201) {
        toast.success(response.data.message);
        resetValue();
        setIsUpdate(false);
        setModalOpen(false);
        refetch();
      }
    }

    if (isUpdate) {
      const response = await updateSchedule(newData);

      if (response?.status === 200) {
        toast.success(response.data.message);
        resetValue();
        setIsUpdate(false);
        setModalOpen(false);
        refetch();
      }
    }
  };

  const handleDelete = async () => {
    const response = await deleteScheduleByDay({ id: initialData.id });

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setModalOpen(false);
      refetch();
    }
  };

  useEffect(() => {
    const days = new Date(currentYear, currentMonth, 0).getDate();
    const daysArray = [...Array(days).keys()].map((day) => day + 1);
    setDaysInMonth(daysArray);
    setFilteredDays(daysArray);
  }, [currentMonth, currentYear]);

  const handleClickUpdate = ({ day, item, user_id }) => {
    if (item[0] && user_id) {
      item[0].user_id = user_id;
      setInitialData(...item);
      setSelectedDay(day);
      setIsUpdate(true);
      setModalOpen(true);
    }
  };

  const handleClickCreate = ({ day, user_id }) => {
    const dayOfMonth = new Date(currentYear, currentMonth - 1, day).getDate();
    const newData = {
      id: "",
      user_id: user_id,
      day: dayOfMonth,
      working_time: [{ start: "", end: "" }],
      is_approve: "",
    };

    setSelectedDay(day);
    setInitialData(newData);
    setIsUpdate(false);
    setModalOpen(true);
  };

  const payForSchedule = async () => {
    const dataForPay = {
      schedule_id: initialData.id,
      user_id: initialData.user_id,
    };

    await handleUpdate(true);

    const response = await addNewAmountForSchedule(dataForPay);

    if (response?.status === 200 || response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setModalOpen(false);
      refetch();
    }
  };

  const handleApplyCustomSchedule = () => {
    if (selectedStartDate && selectedEndDate) {
      const newFilteredDays = daysInMonth.filter(
        (day) => day >= selectedStartDate && day <= selectedEndDate
      );
      setFilteredDays(newFilteredDays);
    } else {
      setFilteredDays(daysInMonth);
    }
  };

  const isFirstWeekLastDay = (year, month, day) => {
    const firstWeek = getWeekRanges(year, month)[0];
    return firstWeek && day === firstWeek.end;
  };

  const calculateHoursForWeek = (employeeDetails, day, weekRange) => {
    let hoursForTheWeek = 0;

    if (
      new Date(currentYear, currentMonth - 1, day).getDay() === 0 ||
      isFirstWeekLastDay(currentYear, currentMonth, day)
    ) {
      const schedule = employeeDetails.schedules.filter((findDay) => {
        const scheduleDay = new Date(findDay.day).getDate();

        return scheduleDay >= weekRange?.start && scheduleDay <= weekRange?.end;
      });

      schedule.forEach((daySchedule) => {
        if (daySchedule) {
          daySchedule?.working_time?.forEach((timeSlot) => {
            const { start, end } = timeSlot;

            if (start && end) {
              const hoursDifference = calculateHoursDifference(start, end);
              hoursForTheWeek += hoursDifference;
            }
          });
        }
      });
    }

    return hoursForTheWeek;
  };

  const getCurrentWeekRange = (day) => {
    const weekRanges = getWeekRanges(currentYear, currentMonth);
    return weekRanges?.find((range) => day >= range?.start && day <= range?.end);
  };

  const calculateTotalHoursForMonth = (employeeDetails) => {
    let totalHoursForMonth = 0;

    Object.values(employeeDetails.schedules).forEach((schedule) => {
      if (
        schedule &&
        schedule.working_time &&
        Array.isArray(schedule.working_time)
      ) {
        schedule.working_time.forEach((timeSlot) => {
          const { start, end } = timeSlot;

          if (start && end) {
            const hoursDifference = calculateHoursDifference(start, end);
            totalHoursForMonth += hoursDifference;
          }
        });
      }
    });

    return totalHoursForMonth;
  };

  let hoursForTheWeek = null;
  let totalHoursForMonth = null;

  return (
    <Main>
      <ScheduleMeNavContainer style={{ width: "500px" }}>
        <Button onClick={() => changeMonth(-1)}>Минулий місяць</Button>
        <Button onClick={() => changeMonth(1)} style={{ marginLeft: "10px" }}>
          Наступний місяць
        </Button>
      </ScheduleMeNavContainer>

      <ScheduleMeNavContainer style={{ marginTop: "10px" }}>
        <div>
          <span>Оберіть початок періоду: </span>
          <FilterSelect
            value={selectedStartDate || ""}
            onChange={(e) => setSelectedStartDate(Number(e.target.value))}
          >
            <FilterOption value="">Оберіть дату</FilterOption>
            {daysInMonth.map((day) => (
              <FilterOption key={day} value={day}>
                {day >= 10 ? day : `0${day}`}.
                {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </FilterOption>
            ))}
          </FilterSelect>
        </div>

        <div style={{ marginLeft: "10px" }}>
          <span>Оберіть кінець періоду: </span>
          <FilterSelect
            value={selectedEndDate || ""}
            onChange={(e) => setSelectedEndDate(Number(e.target.value))}
          >
            <FilterOption value="">Оберіть дату</FilterOption>
            {daysInMonth.map((day) => (
              <FilterOption key={day} value={day}>
                {day >= 10 ? day : `0${day}`}.
                {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
              </FilterOption>
            ))}
          </FilterSelect>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <Button
            onClick={handleApplyCustomSchedule}
            style={{ width: "200px" }}
          >
            Застосувати період
          </Button>
        </div>

        <FlexContainer style={{ marginLeft: "10px" }}>
          <label>Оберіть статус операторів: </label>
          <FilterSelect
            value={isBlocked}
            onChange={(e) => setIsBlocked(e.target.value)}
            style={{ width: "150px" }}
          >
            <FilterOption key={0} value="false">
              Не заблоковані
            </FilterOption>
            <FilterOption key={1} value="true">
              Заблоковані
            </FilterOption>
          </FilterSelect>

          <label style={{ marginLeft: "10px" }}>Оберіть посаду: </label>
          <FilterSelect
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            style={{ marginRight: "5px", width: "200px" }}
          >
            <FilterOption value="">Виберіть посаду</FilterOption>
            {positionData?.map((position) => (
              <FilterOption key={position?.id} value={position?.id}>
                {position?.name}
              </FilterOption>
            ))}
          </FilterSelect>
        </FlexContainer>
      </ScheduleMeNavContainer>

      {isModalOpen && (
        <Modal closeModal={() => handleCancelEdit()}>
          <AddScheduleContainerModal>
            <h2>
              День: {selectedDay >= 10 ? selectedDay : `0${selectedDay}`}.
              {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
            </h2>

            {initialData?.working_time?.length > 0 &&
              initialData?.working_time?.map((slot, index) => (
                <AddedTimeContainer key={index}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AddedTimeInput
                      type="time"
                      value={slot?.start}
                      onChange={(e) =>
                        handleArrayInputChange(
                          "working_time",
                          index,
                          "start",
                          e.target.value
                        )
                      }
                    />
                    -
                    <AddedTimeInput
                      type="time"
                      value={slot.end}
                      onChange={(e) =>
                        handleArrayInputChange(
                          "working_time",
                          index,
                          "end",
                          e.target.value
                        )
                      }
                    />
                    <TrashIconButton
                      type="button"
                      onClick={() => handleRemoveWorkingTime(index)}
                      style={{ width: "30px", marginLeft: "15px" }}
                    >
                      <FaTrash size="20px" />
                    </TrashIconButton>
                  </div>
                </AddedTimeContainer>
              ))}
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                type="button"
                onClick={() =>
                  handleAddWorkingTime("working_time", { start: "", end: "" })
                }
                style={{ width: "75%" }}
              >
                <MdOutlineAddCircle size="25px" />
              </IconButton>
            </div>
            {isUpdate ? (
              <div>
                <ButtonContainer style={{ marginTop: "10px" }}>
                  <SaveButton onClick={() => handleUpdate(false)}>
                    Внести зміни
                  </SaveButton>
                  <TrashButton onClick={handleDelete}>
                    Видалити за день
                  </TrashButton>
                </ButtonContainer>
                <ButtonContainer style={{ marginTop: "10px" }}>
                  <SaveButton onClick={payForSchedule}>
                    Оплатити зміну
                  </SaveButton>
                </ButtonContainer>
              </div>
            ) : (
              <ButtonContainer style={{ marginTop: "10px" }}>
                <SaveButton onClick={() => handleUpdate(false)}>
                  Зберегти
                </SaveButton>
                <TrashButton onClick={handleCancelEdit}>Скасувати</TrashButton>
              </ButtonContainer>
            )}
          </AddScheduleContainerModal>
        </Modal>
      )}

      <ScheduleTable>
        <Thead>
          <ScheduleTr>
            <ScheduleTh style={{width: '22px'}}>№</ScheduleTh>
            <ScheduleTh style={{width: '122px'}}>ПІБ</ScheduleTh>
            <ScheduleTh>Годин за місяць</ScheduleTh>
            {Object.values(filteredDays)?.map((day, idx) => {
              const adjustedIdx = (idx + firstDayOfMonth - 1) % 7;

              const isEndOfWeek =
                new Date(currentYear, currentMonth - 1, day).getDay() === 0 ||
                isFirstWeekLastDay(currentYear, currentMonth, day);

              return (
                <React.Fragment key={idx + 1}>
                  <ScheduleTh>
                    {weekDays[Object.keys(weekDays)[adjustedIdx]]}
                    <br />
                    {day >= 10 ? day : `0${day}`}.
                    {currentMonth >= 10 ? currentMonth : `0${currentMonth}`}
                  </ScheduleTh>
                  {isEndOfWeek && <ScheduleTh>Годин за тиждень</ScheduleTh>}
                </React.Fragment>
              );
            })}
          </ScheduleTr>
        </Thead>
        <tbody>
          {data && data?.length > 0 && data?.map((employee, index) => {
            totalHoursForMonth = calculateTotalHoursForMonth(employee);

            return (
              <ScheduleTr key={employee.user_id}>
                <StickyTd  first style={{width: '40px'}}>{index + 1}</StickyTd>
                <StickyTd style={{width: '142px'}}>{employee.full_name}</StickyTd>
                <ScheduleTd
                  backgroundColor={getColorByHoursMonth(totalHoursForMonth)}
                >
                  {formatToTime(totalHoursForMonth)}
                </ScheduleTd>

                {Object.values(filteredDays)?.map((day, idx) => {
                  const selectedData = employee?.schedules?.filter((item) => {
                    const itemDay = new Date(item.day).getDate();
                    const dayOfMonth = new Date(
                      currentYear,
                      currentMonth - 1,
                      day
                    ).getDate();
                    return itemDay === dayOfMonth;
                  });

                  const isApprove =
                    selectedData?.length > 0 &&
                    selectedData[0].is_approve === "true"
                      ? true
                      : false;

                  const weekRange = getCurrentWeekRange(day);

                  hoursForTheWeek = calculateHoursForWeek(
                    employee,
                    day,
                    weekRange
                  );

                  const isEndOfWeek =
                    new Date(currentYear, currentMonth - 1, day).getDay() ===
                      0 || isFirstWeekLastDay(currentYear, currentMonth, day);

                  return (
                    <React.Fragment key={idx}>
                      <ScheduleTd
                        onClick={() =>
                          handleClickUpdate({
                            day,
                            item: selectedData,
                            user_id: employee.user_id,
                          })
                        }
                        style={{
                          backgroundColor: isApprove ? "green" : "transparent",
                        }}
                      >
                        {selectedData?.length > 0 ? (
                          selectedData.map((item, index) => (
                            <div key={index}>
                              {item?.working_time?.map((timeItem, i) => (
                                <div key={i}>
                                  {timeItem?.start} - {timeItem?.end}
                                </div>
                              ))}
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() =>
                              handleClickCreate({
                                day,
                                user_id: employee.user_id,
                              })
                            }
                          >
                            Вихідний
                          </div>
                        )}
                      </ScheduleTd>

                      {isEndOfWeek && (
                        <ScheduleTd
                          backgroundColor={getColorByHoursWeek(hoursForTheWeek)}
                        >
                          {formatToTime(hoursForTheWeek)}
                        </ScheduleTd>
                      )}
                    </React.Fragment>
                  );
                })}
              </ScheduleTr>
            );
          })}
        </tbody>
      </ScheduleTable>
    </Main>
  );
};

const weekDays = {
  monday: "Понеділок",
  tuesday: "Вівторок",
  wednesday: "Середа",
  thursday: "Четвер",
  friday: "Пятница",
  saturday: "Субота",
  sunday: "Неділя",
};
