import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Option,
  FilterSelect,
  FilterOption,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { RouteTh, RouteTd } from "../ReferralProgram.styled";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchReferralProgram } from "../../../api/referalProgram";
import { fetchReferralProgramStatuses } from "../../../api/referalProgramStatuses";

export const ReferralProgramList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const sortBy = "createdAt";
  const order = "DESC";

  const [selectedStatus, setSelectedStatus] = useState("");

  const { data } = useQuery(
    [
      "referralProgram",
      {
        currentPage,
        pageLimit,
        sortBy,
        order,
        selectedStatus,
      },
    ],
    () =>
      fetchReferralProgram({
        limit: pageLimit,
        page: currentPage,
        sortBy,
        order,
        referralProgramStatusId: selectedStatus,
      })
  );

  const { data: referralProgramStatusData } = useQuery(
    "referralProgramStatuses",
    fetchReferralProgramStatuses
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  return (
    <Main>
      <div style={{ margin: "20px" }}>
        <div
          style={{
            margin: "20px 0",
            display: "flex",
            alignItems: "center",
            heigh: "25px",
            flexWrap: "wrap",
          }}
        >
          <label style={{ marginLeft: "10px" }}>Оберіть статус: </label>
          <FilterSelect
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setCurrentPage(1);
            }}
            style={{ marginRight: "5px", width: "200px" }}
          >
            <Option value="">Виберіть статус</Option>
            {referralProgramStatusData?.data?.map((status) => (
              <FilterOption key={status?.id} value={status?.id}>
                {status?.title}
              </FilterOption>
            ))}
          </FilterSelect>

          <PageLimitSelector
            pageLimit={pageLimit}
            onChange={handlePageLimitChange}
          />
        </div>
      </div>

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "80px" }}>Дата</RouteTh>
            <RouteTh style={{ width: "258px" }}>ПІБ кого привели</RouteTh>
            <RouteTh>Дата виходу в першу зміну</RouteTh>
            <RouteTh>Кількість робочих годин</RouteTh>
            <RouteTh>Статус приведеного</RouteTh>
            <RouteTh>Статус оплати</RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.data?.length > 0 && (
            <React.Fragment>
              {data?.data?.map((item) => (
                <Tr key={item?.id}>
                  <>
                    <RouteTd style={{ width: "102px" }}>
                      {formattedDateToDayMonthYear(item.createdAt)}
                    </RouteTd>
                    <RouteTd style={{ width: "280px" }}>
                      {item.referred_friend_full_name}
                    </RouteTd>
                    <RouteTd>
                      {item.first_day
                        ? formattedDateToDayMonthYear(item.first_day)
                        : ""}
                    </RouteTd>
                    <RouteTd>
                      {item.total_hours ? item.total_hours : "0:00"}
                    </RouteTd>
                    <RouteTd>{item.status_title}</RouteTd>
                    <RouteTd>
                      {item.payment_status === "true"
                        ? "Оплачено"
                        : "Не оплачено"}
                    </RouteTd>
                  </>
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
