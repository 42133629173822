import React, { useState } from "react";
import { useQuery } from "react-query";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchOverallSummary, fetchOverallSummaryDaily } from "../../../api/statistic";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { uk } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { translateLabel } from "../../../helpers/translateLabel";
import { Modal } from "../../Modal/Modal";
import { Button } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { v4 as uuidv4 } from "uuid";

export const OverallSummary = () => {
  const [isModalDateOpen, setIsModalDateOpen] = useState(false);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
  const [period, setPeriod] = useState([
    {
      startDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.01.${currentYear}`)
          : new Date(`${currentMonth}.16.${currentYear}`),
      endDate:
        new Date().getDate() < 16
          ? new Date(`${currentMonth}.15.${currentYear}`)
          : new Date(`${currentMonth}.${lastDayOfMonth}.${currentYear}`),
      key: "selection",
    },
  ]);

  const formattedStartDate = format(period[0]?.startDate, "dd.MM.yyyy");
  const formattedEndDate = format(period[0]?.endDate, "dd.MM.yyyy");
  const formattedStartDateRequest = format(period[0]?.startDate, "yyyy.MM.dd");
  const formattedEndDateRequest = format(period[0]?.endDate, "yyyy.MM.dd");

  const { data, isSuccess, refetch } = useQuery(["getEmployeeStatistic"], () =>
    fetchOverallSummary({
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    })
  );

  const { data: dailyData, isSuccess: isSuccessDailyData, refetch: refetchDaily } = useQuery(["getverallSummaryDaily"], () =>
    fetchOverallSummaryDaily({
      startDate: formattedStartDateRequest,
      endDate: formattedEndDateRequest,
    })
  );

  const customDefaultStaticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const customDefaultInputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: translateLabel(range.label, "uk"),
  }));

  const handleDate = () => {
    refetch();
    refetchDaily()
    setIsModalDateOpen(false);
  };

  return (
    <Main>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ marginRight: "10px" }}
        >{`${formattedStartDate} - ${formattedEndDate}`}</div>
        <Button
          onClick={() => setIsModalDateOpen(true)}
          style={{ width: "200px" }}
        >
          Оберіть період
        </Button>
      </div>
      {isModalDateOpen && (
        <Modal closeModal={() => setIsModalDateOpen(false)}>
          <DateRangePicker
            onChange={(item) => setPeriod([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            locale={uk}
            staticRanges={customDefaultStaticRanges}
            inputRanges={customDefaultInputRanges}
            months={2}
            ranges={period}
            direction="horizontal"
            style={{ margin: "20px" }}
          />
          <Button onClick={() => handleDate()}>Внести зміни</Button>
        </Modal>
      )}

      <Table>
        <Thead>
          <HeadTr>
            <Th style={{ width: "70px" }}>Середній чек</Th>
            <Th style={{ width: "70px" }}>Всього замовлень</Th>
            <Th style={{ width: "70px" }}>Кількість проданих товарів</Th>
            <Th style={{ width: "70px" }}>Кількість робочих годин</Th>
            <Th style={{ width: "70px" }}>Середній час на 1 апрув</Th>
            <Th>Всього викуплених замовлень від всіх оформлених </Th>
            <Th style={{ width: "80px" }}>Відсоток викуплених замовлень </Th>
            <Th>Всього викуплених/невикуплених </Th>
            <Th>Відсоток викуплених від викуплених/невикуплених </Th>
            <Th>По статусах</Th>
            <Th style={{ width: "80px" }}>
              Виплата операторам за погодину оплату
            </Th>
            <Th style={{ width: "80px" }}>Виплата операторам за замовленя</Th>
            <Th style={{ width: "80px" }}>Затрати на невикупи</Th>
            <Th style={{ width: "80px" }}>Дохід від роботи операторів</Th>
            <Th style={{ width: "80px" }}>Профіт</Th>
          </HeadTr>
        </Thead>
        {isSuccess && (!data || data?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            <Tr>
              <Td style={{ width: "92px" }}>
                {data?.average_total_amount} грн
              </Td>
              <Td style={{ width: "92px" }}>{data?.total_order_count}</Td>
              <Td style={{ width: "92px" }}>{data?.total_products}</Td>
              <Td style={{ width: "92px" }}>{data?.total_worked_hours}</Td>
              <Td style={{ width: "92px" }}>{data?.average_time_per_order}</Td>
              <Td>{data?.total_buyout_orders}</Td>
              <Td style={{ width: "102px" }}>
                {data?.buyout_percentage?.toFixed(2)}%
              </Td>
              <Td>{data?.total_relevant_orders}</Td>
              <Td>{data?.buyout_specific_percentage?.toFixed(2)}%</Td>
              <Td>
                {data?.statuses?.map((status) => {
                  const orders_percentage =
                    Number(
                      (
                        (status.order_count /
                          (data.total_order_count + data.total_recall_orders)) *
                        100
                      )?.toFixed(2)
                    ) || 0;
                    const key = uuidv4();

                  return (
                    <p key={key}>
                      <span style={{ fontWeight: "bold" }}>
                        {status.status_title}
                      </span>{" "}
                      <br />
                      Кількість - {status.order_count ? status.order_count : 0} <br />
                      Відсоток - {orders_percentage}%
                    </p>
                  );
                })}
              </Td>
              <Td style={{ width: "102px" }}>
                {data?.total_hourly_salary?.toFixed(0)} грн
              </Td>
              <Td style={{ width: "102px" }}>
                {data?.percent_sum?.toFixed(0)} грн
              </Td>
              <Td style={{ width: "102px" }}>
                {data?.total_delivery_value} грн
              </Td>
              <Td style={{ width: "102px" }}>{data?.total_buyout_value} грн</Td>
              <Td style={{ width: "102px" }}>{data?.profit?.toFixed(0)} грн</Td>
            </Tr>
          </tbody>
        )}
      </Table>

      <Table style={{marginTop: '80px'}}>
        <Thead>
          <HeadTr>
          <Th style={{ width: "70px" }}>Дата</Th>
            <Th style={{ width: "70px" }}>Середній чек</Th>
            <Th style={{ width: "70px" }}>Всього замовлень</Th>
            <Th style={{ width: "70px" }}>Кількість проданих товарів</Th>
            <Th style={{ width: "70px" }}>Кількість робочих годин</Th>
            <Th style={{ width: "70px" }}>Середній час на 1 апрув</Th>
            <Th>Всього викуплених замовлень від всіх оформлених </Th>
            <Th style={{ width: "80px" }}>Відсоток викуплених замовлень </Th>
            <Th>Всього викуплених/невикуплених </Th>
            <Th>Відсоток викуплених від викуплених/невикуплених </Th>
            <Th>По статусах</Th>
            <Th style={{ width: "80px" }}>
              Виплата операторам за погодину оплату
            </Th>
            <Th style={{ width: "80px" }}>Виплата операторам за замовленя</Th>
            <Th style={{ width: "80px" }}>Затрати на невикупи</Th>
            <Th style={{ width: "80px" }}>Дохід від роботи операторів</Th>
            <Th style={{ width: "80px" }}>Профіт</Th>
          </HeadTr>
        </Thead>
        {isSuccessDailyData && (!dailyData || dailyData?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            {dailyData && dailyData.map((item, index) => {
              const key = uuidv4();

              return (
                <Tr key={key}>
                <Td style={{ width: "92px" }}>
                    {item?.date}
                  </Td>
                  <Td style={{ width: "92px" }}>
                    {item?.average_total_amount} грн
                  </Td>
                  <Td style={{ width: "92px" }}>{item?.total_order_count}</Td>
                  <Td style={{ width: "92px" }}>{item?.total_products}</Td>
                  <Td style={{ width: "92px" }}>{item?.total_worked_hours}</Td>
                  <Td style={{ width: "92px" }}>{item?.average_time_per_order}</Td>
                  <Td>{item?.total_buyout_orders}</Td>
                  <Td style={{ width: "102px" }}>
                    {item?.buyout_percentage?.toFixed(2)}%
                  </Td>
                  <Td>{item?.total_relevant_orders}</Td>
                  <Td>{item?.buyout_specific_percentage?.toFixed(2)}%</Td>
                  <Td>
                    {item?.statuses?.map((status) => {
                      const orders_percentage =
                        Number(
                          (
                            (status.order_count /
                              (item.total_order_count + item.total_recall_orders)) *
                            100
                          )?.toFixed(2)
                        ) || 0;
                        const key = uuidv4();
                        
    
                      return (
                        <p key={key}>
                          <span style={{ fontWeight: "bold" }}>
                            {status.status_title}
                          </span>{" "}
                          <br />
                          Кількість - {status.order_count ? status.order_count : 0} <br />
                          Відсоток - {orders_percentage}%
                        </p>
                      );
                    })}
                  </Td>
                  <Td style={{ width: "102px" }}>
                    {item?.total_hourly_salary?.toFixed(0)} грн
                  </Td>
                  <Td style={{ width: "102px" }}>
                    {item?.percent_sum?.toFixed(0)} грн
                  </Td>
                  <Td style={{ width: "102px" }}>
                    {item?.total_delivery_value} грн
                  </Td>
                  <Td style={{ width: "102px" }}>{item?.total_buyout_value} грн</Td>
                  <Td style={{ width: "102px" }}>{item?.profit?.toFixed(0)} грн</Td>
                </Tr>
              )
            })}
          </tbody>
        )}
      </Table>
    </Main>
  );
};
