import styled from '@emotion/styled';
import {
    Td,
    Th,
  } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const PositionTh = styled(Th)`
width: 246px;
`

export const PositionTd = styled(Td)`
width: 268px;
`

export const IconLinkContainer = styled.div`
display: flex;
width: 100%;
`