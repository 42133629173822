import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Option,
  FilterSelect,
  FilterOption,
  TrashIconButton,
  Button,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
  ReferralProgramSelect,
} from "../ReferralProgram.styled";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";
import Select from "react-select";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { fetchUsers } from "../../../api/users";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { addNewAmountForReferralProgram } from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import {
  fetchReferralProgram,
  updateReferralProgram,
  createReferralProgram,
  deleteReferralProgram,
} from "../../../api/referalProgram";
import { fetchReferralProgramStatuses } from "../../../api/referalProgramStatuses";

export const ReferralProgramPrimaryList = () => {
  const [addingNewRoute, setAddingNewRoute] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    inviter_id: "",
    referred_friend_id: "",
    payment_status: "false",
    createdAt: "",
    updateAt: "",
    referral_program_status_id: "",
    inviter_full_name: "",
    referred_friend_full_name: "",
    first_day: '',
    total_hours: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const sortBy = "createdAt";
  const order = "DESC";
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleCancelEdit = () => {
    resetValue();
    setAddingNewRoute(false);
    setIsUpdate(false);
  };

  const handleEditRoute = async (item) => {
    const updatedFormData = {
      ...item,
    };

    setFormData(updatedFormData);
    setIsUpdate(true);
    setAddingNewRoute(true);
  };

  const { data, refetch } = useQuery(
    [
      "referralProgram",
      {
        currentPage,
        pageLimit,
        sortBy,
        order,
        selectedEmployee,
        selectedStatus,
      },
    ],
    () =>
      fetchReferralProgram({
        limit: pageLimit,
        page: currentPage,
        sortBy,
        order,
        inviter_id: selectedEmployee,
        referralProgramStatusId: selectedStatus,
      })
  );

  const { data: referralProgramStatusData } = useQuery(
    "referralProgramStatuses",
    fetchReferralProgramStatuses
  );

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      sortBy: "full_name",
      order: "ASC",
      limit: 4000
    })
  );

  const handleDeleteRoute = async (id) => {
    const response = await deleteReferralProgram(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      handleCancelEdit();
      refetch();
    }
  };

  const changeFormData = () => {
    const updatedFormData = {
      ...formData,
      inviter_id: formData.inviter_id?.value || "",
      referred_friend_id: formData.referred_friend_id?.value || "",
    };

    return updatedFormData;
  };

  const resetValue = () => {
    setFormData({
      id: "",
      inviter_id: "",
      referred_friend_id: "",
      payment_status: "false",
      createdAt: "",
      updateAt: "",
      referral_program_status_id: "",
      inviter_full_name: "",
      referred_friend_full_name: "",
    });
  };

  const handleUpdate = async () => {
    const getUpdatedFormData = changeFormData();
    const response = await updateReferralProgram(getUpdatedFormData);

    if (response?.status === 200) {
        toast.success(response.data.message);
        resetValue();
        setIsUpdate(false);
        setAddingNewRoute(false);
        refetch();
    }
  };

  const handleReferralProgramPayment = async (id) => {
    const amountResponse = await addNewAmountForReferralProgram({referral_program_id: id});

    if(amountResponse?.status !== 201 && (amountResponse?.message || amountResponse?.data?.message)) {
      toast.error(amountResponse?.message || amountResponse?.data?.message);
    }

    if (amountResponse?.status === 201) {
      toast.success(amountResponse?.message || amountResponse?.data.message);
      
      const response = await updateReferralProgram({id, payment_status: 'true'});

      if(response?.status !== 200 && (response?.message || response?.data?.message)) {
        toast.error(response.message || response.data.message);
      }
  
      if (response?.status === 200) {
        toast.success(response?.message || response?.data.message);
        resetValue();
        setIsUpdate(false);
        setAddingNewRoute(false);
        refetch();
      }
    }
  }

  const handleSubmit = async () => {
    const getUpdatedFormData = {
      inviter_id: formData.inviter_id?.value || "",
      referred_friend_id: formData.referred_friend_id?.value || "",
    }

    const response = await createReferralProgram(getUpdatedFormData);

    if(response?.status !== 201) {
      toast.error(response.data.message);
    }

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  return (
    <Main>
      <div style={{ margin: "20px" }}>
        <div
          style={{
            margin: "20px 0",
            display: "flex",
            alignItems: "center",
            heigh: "25px",
            flexWrap: "wrap",
          }}
        >
          <label>Оберіть хто привів: </label>
          <FilterSelect
            value={selectedEmployee}
            onChange={(e) => {
              setSelectedEmployee(e.target.value);
              setCurrentPage(1);
            }}
            style={{ marginRight: "5px", width: "200px" }}
          >
            <Option value="">Виберіть оператора</Option>
            {usersData?.usersData?.map((user) => (
              <FilterOption key={user?.id} value={user?.id}>
                {user?.full_name}
              </FilterOption>
            ))}
          </FilterSelect>

          <label style={{ marginLeft: "10px" }}>Оберіть статус: </label>
          <FilterSelect
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setCurrentPage(1);
            }}
            style={{ marginRight: "5px", width: "200px" }}
          >
            <Option value="">Виберіть статус</Option>
            {referralProgramStatusData?.data?.map((status) => (
              <FilterOption key={status?.id} value={status?.id}>
                {status?.title}
              </FilterOption>
            ))}
          </FilterSelect>

          <PageLimitSelector
            pageLimit={pageLimit}
            onChange={handlePageLimitChange}
          />
        </div>
      </div>

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "80px" }}>Дата</RouteTh>
            <RouteTh style={{ width: "258px" }}>ПІБ хто привів</RouteTh>
            <RouteTh style={{ width: "258px" }}>ПІБ кого привели</RouteTh>
            <RouteTh>Дата виходу в першу зміну</RouteTh>
            <RouteTh>Кількість робочих годин</RouteTh>
            <RouteTh>Статус приведеного</RouteTh>
            <RouteTh>Статус оплати</RouteTh>
            <RouteTh>Оплатити</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          <Tr>
            {addingNewRoute && !isUpdated ? (
              <>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd style={{ width: "280px" }}>
                  <Select
                    value={{
                      label: formData.inviter_id.label || "Виберіть хто привів",
                      value: formData.inviter_id.value || "0",
                    }}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        inviter_id: selectedOption,
                      }))
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: "3",
                        width: "100%",
                      }),
                    }}
                    options={[
                      { label: "Виберіть хто привів", value: "0" },
                      ...(usersData?.usersData || []).map((user) => ({
                        label: user.full_name,
                        value: user.id,
                      })),
                    ]}
                    placeholder="Виберіть хто привів"
                  />
                </RouteTd>
                <RouteTd style={{ width: "280px" }}>
                  <Select
                    value={{
                      label:
                        formData.referred_friend_id.label ||
                        "Виберіть кого привели",
                      value: formData.referred_friend_id.value || "0",
                    }}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        referred_friend_id: selectedOption,
                      }))
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: "3",
                        width: "100%",
                      }),
                    }}
                    options={[
                      { label: "Виберіть кого привели", value: "0" },
                      ...(usersData?.usersData || []).map((user) => ({
                        label: user.full_name,
                        value: user.id,
                      })),
                    ]}
                    placeholder="Виберіть кого привели"
                  />
                </RouteTd>

                <RouteTd></RouteTd>

                <RouteTd></RouteTd>

                <RouteTd></RouteTd>

                <RouteTd></RouteTd>

                <RouteTd></RouteTd>

                <Td>
                  <IconLinkContainer>
                    <IconButton onClick={handleSubmit}>
                      <FaSave size="20px" />
                    </IconButton>
                    <TrashIconButton onClick={() => handleCancelEdit()}>
                      <MdCancel size="25px" />
                    </TrashIconButton>
                  </IconLinkContainer>
                </Td>
              </>
            ) : (
              <>
                <RouteTd style={{ width: "102px" }}></RouteTd>
                <RouteTd style={{ width: "280px" }}></RouteTd>
                <RouteTd style={{ width: "280px" }}></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd></RouteTd>
                <RouteTd></RouteTd>
                <Td>
                  <IconButton
                    onClick={() => setAddingNewRoute(true)}
                    style={{ width: "75%" }}
                  >
                    <MdOutlineAddCircle size="25px" />
                  </IconButton>
                </Td>
              </>
            )}
          </Tr>
          {data?.data?.length > 0 && (
            <React.Fragment>
              {data?.data?.map((item) => (
                <Tr key={item?.id}>
                  {addingNewRoute && isUpdated && formData?.id === item?.id ? (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </RouteTd>
                      <RouteTd style={{ width: "280px" }}>
                  <Select
                    value={{
                      label: formData.inviter_id.label || formData.inviter_full_name || "Виберіть хто привів",
                      value: formData.inviter_id.value || formData.inviter_id || "0",
                    }}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        inviter_id: selectedOption,
                      }))
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: "3",
                        width: "100%",
                      }),
                    }}
                    options={[
                      { label: "Виберіть хто привів", value: "0" },
                      ...(usersData?.usersData || []).map((user) => ({
                        label: user.full_name,
                        value: user.id,
                      })),
                    ]}
                    placeholder="Виберіть хто привів"
                  />
                </RouteTd>

                <RouteTd style={{ width: "280px" }}>
                  <Select
                    value={{
                      label:
                        formData.referred_friend_id.label ||
                        formData.referred_friend_full_name ||
                        "Виберіть кого привели",
                      value: formData.referred_friend_id.value || 
                      formData.referred_friend_id || "0",
                    }}
                    onChange={(selectedOption) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        referred_friend_id: selectedOption,
                      }))
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "100%",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: "3",
                        width: "100%",
                      }),
                    }}
                    options={[
                      { label: "Виберіть кого привели", value: "0" },
                      ...(usersData?.usersData || []).map((user) => ({
                        label: user.full_name,
                        value: user.id,
                      })),
                    ]}
                    placeholder="Виберіть кого привели"
                  />
                </RouteTd>

                <RouteTd>
                        {formData.first_day ? formattedDateToDayMonthYear(formData.first_day) : ""}
                      </RouteTd>
                      <RouteTd>{formData.total_hours ? formData.total_hours : '0:00'}</RouteTd>

                      <RouteTd >
                        <ReferralProgramSelect
                          value={formData?.referral_program_status_id}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              referral_program_status_id: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        >
                          <Option value="">Виберіть статус</Option>
                          {referralProgramStatusData?.data?.map((status) => (
                            <Option key={status?.id} value={status?.id}>
                              {status?.title}
                            </Option>
                          ))}
                        </ReferralProgramSelect>
                      </RouteTd>

                      <RouteTd>
                        {formData.payment_status === "true"
                          ? "Оплачено"
                          : "Не оплачено"}
                      </RouteTd>

                      <RouteTd>
                          
                      </RouteTd>

                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <RouteTd style={{ width: "102px" }}>
                        {formattedDateToDayMonthYear(item.createdAt)}
                      </RouteTd>
                      <RouteTd style={{ width: "280px" }}>
                        {item.inviter_full_name}
                      </RouteTd>
                      <RouteTd style={{ width: "280px" }}>
                        {item.referred_friend_full_name}
                      </RouteTd>
                      <RouteTd>
                        {item.first_day ? formattedDateToDayMonthYear(item.first_day) : ""}
                      </RouteTd>
                      <RouteTd>{item.total_hours ? item.total_hours : '0:00'}</RouteTd>
                      <RouteTd>{item.status_title}</RouteTd>
                      <RouteTd>
                        {item.payment_status === "true"
                          ? "Оплачено"
                          : "Не оплачено"}
                      </RouteTd>
                      {item.payment_status === "false" ?                       <RouteTd>
                        <Button style={{ width: "100%" }} onClick={() => handleReferralProgramPayment(item.id)}>Оплатити</Button>
                      </RouteTd> : <RouteTd></RouteTd>}
                      <Td>
                        {formData?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton onClick={() => handleEditRoute(item)}>
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => handleDeleteRoute(item.id)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
