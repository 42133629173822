import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { formatDate } from "../../../helpers/dateAndTime/formatDate";
import {
  BlockedButton,
  BlockedButtonRed,
  IsBlockedContainer,
  UserFaMinusCircle,
  UserFaTimesCircle,
  UserFilterSelect,
  UserFilterOption,
} from "./UserList.styled";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  StickyTd,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { fetchUsers, updateUser } from "../../../api/users";
import { Search } from "../../SearchComponent/SearchComponent";
import { fetchPosition } from "../../../api/settings";
import { UserRow } from "./InviterRow";
import { getFiredEmployeeStatuses } from "../../../api/firedEmployeeStatuses";
import { getEmployeeStatuses } from "../../../api/employeeStatus";
export const UserList = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("id");
  const [order, setOrder] = useState("ASC");
  const [isBlocked, setIsBlocked] = useState(
    sessionStorage.getItem("isBlocked") || false
  );
  const [jobTitle, setJobTitle] = useState(
    sessionStorage.getItem("jobTitle") || ""
  );
  const [employeeStatus, setEmployeeStatus] = useState(
    sessionStorage.getItem("employeeStatus") || ""
  );
  const [firedStatus, setFiredStatus] = useState(
    sessionStorage.getItem("firedStatus") || ""
  );

  useEffect(() => {
    sessionStorage.setItem("firedStatus", firedStatus);
    sessionStorage.setItem("jobTitle", jobTitle);
    sessionStorage.setItem("employeeStatus", employeeStatus);
    sessionStorage.setItem("isBlocked", isBlocked);
  }, [firedStatus, jobTitle, employeeStatus, isBlocked]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);

  const { data: positionData } = useQuery("position", fetchPosition);

  const { data, error, isLoading, isSuccess, refetch } = useQuery(
    [
      "getAllEmployees",
      {
        searchQuery,
        sortBy,
        order,
        isBlocked,
        currentPage,
        pageLimit,
        jobTitle,
        employeeStatus,
        firedStatus,
      },
    ],
    () =>
      fetchUsers({
        search: searchQuery,
        sortBy,
        order,
        isBlocked,
        jobTitle,
        page: currentPage,
        limit: pageLimit,
        employeeStatus,
        firedStatus,
      })
  );

  const { data: firedEmployeeStatusesData } = useQuery(
    "firedEmployeeStatuses",
    getFiredEmployeeStatuses
  );

  const { data: employeeStatusesData } = useQuery(
    "getEmployeeStatuses",
    getEmployeeStatuses
  );

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearch = (event) => {
    event?.preventDefault();
    setSearchQuery(inputValue);
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const handleBlockUser = async (id, isBlocked, event) => {
    event.stopPropagation();
    const reverseIsBlocked = isBlocked === "true" ? "false" : "true";

    const response = await updateUser({ id, isBlocked: reverseIsBlocked });
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      refetch();
    }
  };

  const handleSortChange = (field) => {
    setSortBy(field);
    setOrder(order === "ASC" ? "DESC" : "ASC");
    refetch();
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return order === "ASC" ? "▲" : "▼";
    }
    return "";
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const getIcon = (value) => {
    if (value) {
      return <UserFaMinusCircle fill="green" />;
    } else {
      return <UserFaTimesCircle fill="red" />;
    }
  };

  const getFiredStatus = (id) => {
    const getStatus = firedEmployeeStatusesData?.data?.filter(
      (status) => status.id === id
    );

    if (getStatus && getStatus[0]) {
      return getStatus[0].title;
    }

    return "";
  };

  const getEmployeestatus = (id) => {
    const getStatus = employeeStatusesData?.data?.filter(
      (status) => status.id === id
    );

    if (getStatus && getStatus[0]) {
      return getStatus[0].title;
    }

    return "";
  };

  return (
    <Main>
      <Search
        handleSearch={handleSearch}
        handleInputChange={handleInputChange}
        handleKeyPress={handleKeyPress}
        inputValue={inputValue}
      />
      <IsBlockedContainer>
        <label>Оберіть статус операторів: </label>
        <UserFilterSelect
          value={isBlocked}
          onChange={(e) => setIsBlocked(e.target.value)}
        >
          <UserFilterOption key={0} value="false">
            Не заблоковані
          </UserFilterOption>
          <UserFilterOption key={1} value="true">
            Заблоковані
          </UserFilterOption>
        </UserFilterSelect>

        <label style={{ marginLeft: "10px" }}>Оберіть посаду: </label>
        <UserFilterSelect
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <UserFilterOption value="">Виберіть посаду</UserFilterOption>
          {positionData?.map((position) => (
            <UserFilterOption key={position?.id} value={position?.id}>
              {position?.name}
            </UserFilterOption>
          ))}
        </UserFilterSelect>

        <label style={{ marginLeft: "10px" }}>Статус оператора: </label>
        <UserFilterSelect
          value={employeeStatus}
          onChange={(e) => setEmployeeStatus(e.target.value)}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <UserFilterOption value="">Виберіть cтатус</UserFilterOption>
          {employeeStatusesData?.data?.map((status) => (
            <UserFilterOption key={status?.id} value={status?.id}>
              {status?.title}
            </UserFilterOption>
          ))}
        </UserFilterSelect>

        <label style={{ marginLeft: "10px" }}>Статус звільненя: </label>
        <UserFilterSelect
          value={firedStatus}
          onChange={(e) => setFiredStatus(e.target.value)}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <UserFilterOption value="">Виберіть cтатус</UserFilterOption>
          {firedEmployeeStatusesData?.data?.map((status) => (
            <UserFilterOption key={status?.id} value={status?.id}>
              {status?.title}
            </UserFilterOption>
          ))}
        </UserFilterSelect>
        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </IsBlockedContainer>
      <Table>
        <Thead>
          <HeadTr>
            <Th style={{ width: "18px" }}>№</Th>
            <Th onClick={() => handleSortChange("full_name")}>
              ПІБ
              {renderSortIcon("full_name")}
            </Th>
            <Th onClick={() => handleSortChange("email")}>
              Електронна пошта
              {renderSortIcon("email")}
            </Th>
            <Th onClick={() => handleSortChange("telegram")}>
              Телеграм
              {renderSortIcon("telegram")}
            </Th>
            <Th onClick={() => handleSortChange("id_job_title")}>
              Посада
              {renderSortIcon("id_job_title")}
            </Th>
            <Th onClick={() => handleSortChange("employee_status_id")}>
              Група оператора
              {renderSortIcon("employee_status_id")}
            </Th>
            <Th>Номер картки </Th>
            <Th onClick={() => handleSortChange("inviter_id")}>
              Хто привів
              {renderSortIcon("inviter_id")}
            </Th>
            <Th onClick={() => handleSortChange("education_day")}>
              Дата навчання
              {renderSortIcon("education_day")}
            </Th>
            <Th
              onClick={() => handleSortChange("chat_id")}
              style={{ width: "72px" }}
            >
              Інтеграція з ботом
              {renderSortIcon("chat_id")}
            </Th>
            <Th
              onClick={() => handleSortChange("iban")}
              style={{ width: "42px" }}
            >
              IBAN
              {renderSortIcon("iban")}
            </Th>
            <Th
              onClick={() => handleSortChange("identification_number")}
              style={{ width: "72px" }}
            >
              ЄРДПОУ
              {renderSortIcon("identification_number")}
            </Th>
            <Th
              onClick={() => handleSortChange("receiver_initial")}
              style={{ width: "92px" }}
            >
              Дані отримувача
              {renderSortIcon("receiver_initial")}
            </Th>
            <Th onClick={() => handleSortChange("comment")}>
              Комментар
              {renderSortIcon("comment")}
            </Th>
            <Th onClick={() => handleSortChange("phone_number")}>
              Номер телефону
              {renderSortIcon("phone_number")}
            </Th>
            <Th onClick={() => handleSortChange("city")}>
              Місто {renderSortIcon("city")}
            </Th>
            <Th onClick={() => handleSortChange("address")}>
              Адреса проживання {renderSortIcon("address")}
            </Th>
            <Th onClick={() => handleSortChange("birthday")}>
              День народження
              {renderSortIcon("birthday")}
            </Th>
            <Th onClick={() => handleSortChange("createdAt")}>
              Користувача створено
              {renderSortIcon("createdAt")}
            </Th>
            <Th onClick={() => handleSortChange("updateAt")}>
              Останє оновленя
              {renderSortIcon("updateAt")}
            </Th>
            <Th onClick={() => handleSortChange("fired_status_id")}>
              Статус звільненя
              {renderSortIcon("fired_status_id")}
            </Th>
            <Th onClick={() => handleSortChange("isBlocked")}>
              Статус
              {renderSortIcon("isBlocked")}
            </Th>
            <Th>Панель управліня</Th>
          </HeadTr>
        </Thead>
        {error && <div>Помилка під час завантаження користувачів</div>}
        {isSuccess && (!data || data?.usersData?.length === 0) ? (
          <div>
            За вашим запитом нічого не знайдено, будь ласка спробуйте ще раз
          </div>
        ) : (
          <tbody>
            {data?.usersData?.map((user, index) => (
              <Tr key={user?.id}>
                <StickyTd first style={{ width: "40px" }}>
                  {index + 1}
                </StickyTd>
                <StickyTd>{user.full_name}</StickyTd>
                <Td>{user.email}</Td>
                <Td>{user.telegram}</Td>
                <Td>{user.job_title}</Td>
                {user.employee_status_id ? (
                  <Td>{getEmployeestatus(user.employee_status_id)}</Td>
                ) : (
                  <Td></Td>
                )}
                <Td>{user.card} </Td>
                {user.inviter_id ? <UserRow user={user} /> : <Td></Td>}
                <Td>{formatDate(user.education_day)}</Td>
                <Td style={{ width: "94px" }}>{getIcon(user.chat_id)} </Td>
                <Td style={{ width: "64px" }}>{getIcon(user.iban)} </Td>
                <Td style={{ width: "94px" }}>
                  {getIcon(user.identification_number)}{" "}
                </Td>
                <Td style={{ width: "114px" }}>
                  {getIcon(user.receiver_initial)}{" "}
                </Td>
                <Td>{user.comment}</Td>
                <Td>{user.phone_number}</Td>
                <Td>{user.city}</Td>
                <Td>{user.address}</Td>
                <Td>{formatDate(user.birthday)}</Td>
                <Td>{formatDate(user.createdAt)}</Td>
                <Td>{formatDate(user.updateAt)}</Td>
                {user.fired_status_id ? (
                  <Td>{getFiredStatus(user.fired_status_id)}</Td>
                ) : (
                  <Td></Td>
                )}
                <Td>
                  {user.isBlocked === "true" ? (
                    <BlockedButton
                      onClick={(event) =>
                        handleBlockUser(user.id, user.isBlocked, event)
                      }
                    >
                      Розблокувати
                    </BlockedButton>
                  ) : (
                    <BlockedButtonRed
                      onClick={(event) =>
                        handleBlockUser(user.id, user.isBlocked, event)
                      }
                    >
                      Заблокувати
                    </BlockedButtonRed>
                  )}
                </Td>
                <Td>
                  {" "}
                  <BlockedButton
                    onClick={(event) => navigate(`/user/change/${user.id}`)}
                  >
                    Редагувати
                  </BlockedButton>
                </Td>
              </Tr>
            ))}
          </tbody>
        )}
        {!isLoading && !isSuccess && (
          <div>Помилка під час завантаження користувачів</div>
        )}
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
