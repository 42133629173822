import { FaArrowDown, FaMoneyBillWave } from "react-icons/fa";
import { MdRemoveCircleOutline } from "react-icons/md";
import { BiWalletAlt } from "react-icons/bi";

export const OutcomeIcon = () => (
  <div>
    <FaArrowDown style={{ color: "red", fontSize: "24px" }} />
    <FaMoneyBillWave style={{ color: "red", fontSize: "24px" }} />
    <MdRemoveCircleOutline style={{ color: "red", fontSize: "24px" }} />
    <BiWalletAlt style={{ color: "red", fontSize: "24px" }} />
  </div>
);
