import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const addNewAmountForOrder = async (data) => {
  try {
    const response = await makeRequestSQL.post("/balance/order", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const addNewAmountForSchedule = async (data) => {
  try {
    const response = await makeRequestSQL.post("/balance/schedule", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const addNewAmountForReferralProgram = async (data) => {
  try {
    const response = await makeRequestSQL.post("/balance/referral-program", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const addNewAmountForAdittionalBonus = async (data) => {
  try {
    const response = await makeRequestSQL.post("/balance/additional", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const outcomePayment = async (data) => {
  try {
    const response = await makeRequestSQL.post("/balance/outcome", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchBalanceMe = async ({ page, limit, startDate, endDate, salaryDay }) => {
  try {
    const response = await makeRequestSQL.get("/balance/me", {
      params: {
        page,
        limit,
        startDate,
        endDate,
        salaryDay
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchBalanceAll = async ({
  page,
  limit,
  startDate,
  endDate,
  sortBy,
  order,
  employee,
  paymentOption
}) => {
  try {
    const response = await makeRequestSQL.get("/balance/primary-list", {
      params: {
        page,
        limit,
        startDate,
        endDate,
        sortBy,
        order,
        employee,
        paymentOption
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchBalanceOverallSummary = async ({
  page,
  limit,
  startDate,
  endDate,
  sortBy,
  order,
  employee
}) => {
  try {
    const response = await makeRequestSQL.get("/balance/overrall-summary", {
      params: {
        page,
        limit,
        startDate,
        endDate,
        sortBy,
        order,
        employee
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchBalanceOverallSummaryByEmployee = async ({
  page,
  limit,
  startDate,
  endDate,
  sortBy,
  order,
  employee
}) => {
  try {
    const response = await makeRequestSQL.get("/balance/overrall-summary-by-employee", {
      params: {
        page,
        limit,
        startDate,
        endDate,
        sortBy,
        order,
        employee
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchSalary = async ({
  page,
  limit,
  salaryDay,
  sortBy,
  order,
  employee,
  isBlocked
}) => {
  try {
    const response = await makeRequestSQL.get("/balance/salary", {
      params: {
        page,
        limit,
        salaryDay,
        sortBy,
        order,
        employee,
        isBlocked
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const updateBalance = async (data) => {
  try {
    const response = await makeRequestSQL.put("/balance", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
