import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchReferralProgram = async ({
    sortBy,
    order,
    currentPage,
    pageLimit,
    id,
    referralProgramStatusId,
    inviter_id
  }) => {
    try {
      const response = await makeRequestSQL.get('/referral-program/', {
        params: {
          sortBy,
          order,
          page: currentPage,
          limit: pageLimit,
          id,
          referralProgramStatusId,
          inviter_id
        },
      });
      const decryptedData = await  decryptMiddleware(response.data);
  
      return decryptedData.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const updateReferralProgram = async data => {
    try {
      const response = await makeRequestSQL.put('/referral-program/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const createReferralProgram = async data => {
    try {
      const response = await makeRequestSQL.post('/referral-program/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const deleteReferralProgram = async id => {
    try {
      const response = await makeRequestSQL.delete('/referral-program/', {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };