import styled from '@emotion/styled';
import {
    Td,
    Th,
  } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";

export const ReferralProgramTh = styled(Th)`
width: 446px;
`

export const ReferralProgramTd = styled(Td)`
width: 468px;
`

export const IconLinkContainer = styled.div`
display: flex;
width: 100%;
`