import {
  UserFaMinusCircle,
  UserFaTimesCircle,
  OnlineTd,
  OnlineTh,
} from "./OnlineUsers.styled";
import React from "react";
import { useQuery } from "react-query";
import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import { fetchOnlineUsers } from "../../api/users";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
} from "../../components/GlobalComponentsAndStyles/GlobalStyles.styled";
import { ScrollToTopButton } from "../../components/ScrollToTopButton/ScrollToTopButton";

export const OnlineUsers = () => {
  const { data } = useQuery("onlineUsers", fetchOnlineUsers);

  const getIcon = (value) => {
    if (value === "true") {
      return <UserFaMinusCircle fill="green" />;
    } else {
      return <UserFaTimesCircle fill="red" />;
    }
  };

  return (
    <Main>
      <Table>
        <Thead>
          <HeadTr>
          <OnlineTh style={{width: '20px'}}>№</OnlineTh>
            <OnlineTh>ПІБ</OnlineTh>
            <OnlineTh>Статус</OnlineTh>
            <OnlineTh>Останій раз онлайн</OnlineTh>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.map((item, index) => {
            let formattedDateTime;

            if (item?.last_online) {
              const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                timeZone: "UTC",
              };

              formattedDateTime = new Date(item?.last_online).toLocaleString(
                options
              );
            } else {
              formattedDateTime = "";
            }

            return (
              <Tr key={item?.id_employee}>
                <OnlineTd style={{width: '42px'}}>{index + 1}</OnlineTd>
                <OnlineTd>{item?.full_name}</OnlineTd>
                <OnlineTd>{getIcon(item?.is_online)}</OnlineTd>
                <OnlineTd>{formattedDateTime}</OnlineTd>
              </Tr>
            );
          })}
        </tbody>
      </Table>
      <ScrollToTopButton />
    </Main>
  );
};
