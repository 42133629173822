import { FaArrowUp, FaMoneyBillWave } from "react-icons/fa";
import { MdAddCircleOutline } from "react-icons/md";
import { BiWallet } from "react-icons/bi";

export const IncomeIcon = () => (
    <div>
      <FaArrowUp style={{ color: "green", fontSize: "24px" }} />
      <FaMoneyBillWave style={{ color: "green", fontSize: "24px" }} />
      <MdAddCircleOutline style={{ color: "green", fontSize: "24px" }} />
      <BiWallet style={{ color: "green", fontSize: "24px" }} />
    </div>
  );