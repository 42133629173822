import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchNews = async ({
  sortBy,
  searchQuery,
  currentPage,
  pageLimit,
  id,
  job_title,
  order,
}) => {
  try {
    const response = await makeRequestSQL.get("/news/", {
      params: {
        sortBy,
        search: searchQuery,
        page: currentPage,
        limit: pageLimit,
        id,
        job_title,
        order,
      },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchMyNews = async ({
  sortBy,
  searchQuery,
  currentPage,
  pageLimit,
  order,
}) => {
  try {
    const response = await makeRequestSQL.get("/news/me", {
      params: {
        sortBy,
        search: searchQuery,
        page: currentPage,
        limit: pageLimit,
        order,
      },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateNews = async (data) => {
  try {
    const response = await makeRequestSQL.put("/news/", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createNews = async (data) => {
  try {
    const response = await makeRequestSQL.post("/news/", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteNews = async (id) => {
  try {
    const response = await makeRequestSQL.delete("/news/", {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const approveNews = async (id) => {
  try {
    const response = await makeRequestSQL.post("/news/approve-views", id);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};