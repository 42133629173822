import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";


export const createPosition = async (name) => {
  try {
    const response = await makeRequestSQL.post("/position", name);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchPosition = async () => {
  try {
    const response = await makeRequestSQL.get("/position");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const deletePosition = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/position`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updatePosition = async (data) => {
  try {
    const response = await makeRequestSQL.put("/position", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createRoute = async (name) => {
  try {
    const response = await makeRequestSQL.post("/route/main-route", name);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchRoute = async () => {
  try {
    const response = await makeRequestSQL.get("/route/main-route");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteRoute = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/route/main-route`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateRoute = async (data) => {
  try {
    const response = await makeRequestSQL.put("/route/main-route", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createRouteSecondary= async (name) => {
  try {
    const response = await makeRequestSQL.post("/route/secondary-route", name);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchRouteSecondary = async () => {
  try {
    const response = await makeRequestSQL.get("/route/secondary-route");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteRouteSecondary = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/route/secondary-route`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateRouteSecondary = async (data) => {
  try {
    const response = await makeRequestSQL.put("/route/secondary-route", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchPermission = async (path) => {
  try {
    const response = await makeRequestSQL.get("/route/route-permission", {
      params: { path },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
    throw error;
  }
};

export const createContactStatuses = async (title) => {
  try {
    const response = await makeRequestSQL.post("/contact/contact-status", title);
    
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const getContactStatuses = async () => {
  try {
    const response = await makeRequestSQL.get("/contact/contact-status");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};


export const deleteContactStatuses = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/contact/contact-status`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateContactStatuses = async (data) => {
  try {
    const response = await makeRequestSQL.put("/contact/contact-status", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};