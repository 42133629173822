import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Option,
  FilterSelect,
  FilterOption,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
} from "./MySalary.styled";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import {
  fetchBalanceMe,
  fetchSalary,
} from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { IncomeIcon } from "../../GlobalComponentsAndStyles/IncomeIcon";
import { OutcomeIcon } from "../../GlobalComponentsAndStyles/OutcomeIcon";

export const MySalary = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(500);
  const [totalPages, setTotalPages] = useState(1);
  const [salaryDay, setSalaryDay] = useState("");


  const { data } = useQuery(
    [
      "salary",
      {
        salaryDay,
      },
    ],
    () =>
      fetchSalary({
        salaryDay,
      })
  );

  const { data: balanceData } = useQuery(
    [
      "balance",
      {
        currentPage,
        pageLimit,
        salaryDay,
      },
    ],
    () =>
      fetchBalanceMe({
        limit: pageLimit,
        page: currentPage,
        salaryDay,
      })
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (balanceData) {
      setTotalPages(balanceData?.totalPages);
    }
  }, [balanceData, pageLimit]);

    const getIcon = (value) => {
      if (value === "income") {
        return <IncomeIcon />;
      } else {
        return <OutcomeIcon />;
      }
    };

  return (
    <Main>
      <div
        style={{
          margin: "20px",
          display: "flex",
          alignItems: "center",
          heigh: "25px",
          flexWrap: "wrap",
        }}
      >
        <label style={{ marginLeft: "10px" }}>Виберіть день виплати: </label>
        <FilterSelect
          value={salaryDay}
          onChange={(e) => {
            setSalaryDay(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть день виплати</Option>
          {data?.uniqueDays?.map((day, index) => (
            <FilterOption key={index + 10} value={day}>
              {formattedDateToDayMonthYear(day)}
            </FilterOption>
          ))}
        </FilterSelect>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "100px" }}>Замовленям</RouteTh>
            <RouteTh style={{ width: "100px" }}>Погодина</RouteTh>
            <RouteTh style={{ width: "100px" }}>Приведи друга</RouteTh>
            <RouteTh style={{ width: "100px" }}>Бонус</RouteTh>
            <RouteTh style={{ width: "100px" }}>Списано</RouteTh>
            <RouteTh style={{ width: "100px" }}>Всього</RouteTh>
            <RouteTh style={{ width: "100px" }}>Залишок</RouteTh>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.data?.length > 0 && (
            <React.Fragment>
              {data?.data?.map((item) => (
                <Tr key={item?.user_id}>
                  <>
                    <RouteTd style={{ width: "122px" }}>
                      {item?.order_sum} грн
                    </RouteTd>

                    <RouteTd style={{ width: "122px" }}>
                      {item?.schedule_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "122px" }}>
                      {item?.referral_program_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "122px" }}>
                      {item?.additional_bonus_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "122px" }}>
                      {item?.salary_amount_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "122px" }}>
                      {item?.total_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "122px" }}>
                      {item?.total_sum - item?.salary_amount_sum} грн
                    </RouteTd>
                  </>
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>

            <Table style={{marginTop: '50px'}}>
              <Thead>
                <HeadTr>
                  <RouteTh>Дата нарахуваня</RouteTh>
                  <RouteTh>Сума</RouteTh>
                  <RouteTh style={{ width: "582px" }}>Коментар</RouteTh>
                  <RouteTh style={{ width: "80px" }}>Дата виплати</RouteTh>
                  <RouteTh style={{ width: "80px" }}>Операція</RouteTh>
                </HeadTr>
              </Thead>
              <tbody>
                {balanceData?.balanceData?.length > 0 && (
                  <React.Fragment>
                    {balanceData?.balanceData?.map((item) => (
                      <Tr key={item?.id}>
                        <>
                          <RouteTd>
                            {formattedDateToDayMonthYear(item.createdAt)}
                          </RouteTd>
      
                          <RouteTd>{item?.amount} грн</RouteTd>
                          <RouteTd style={{ width: "604px" }}>
                            {item?.comment}
                          </RouteTd>
                          <RouteTd style={{ width: "102px" }}>
                            {formattedDateToDayMonthYear(item.salary_day)}
                          </RouteTd>
                          <RouteTd style={{ width: "102px" }}>
                            {getIcon(item.payment_option)}
                          </RouteTd>
                        </>
                      </Tr>
                    ))}
                  </React.Fragment>
                )}
              </tbody>
            </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
