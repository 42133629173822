import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createRouteSecondary,
  fetchRoute,
  fetchPosition,
  fetchRouteSecondary,
  updateRouteSecondary,
  deleteRouteSecondary,
} from "../../../api/settings";
import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  IconButton,
  HeadTr,
  Text,
  Select,
  Option,
  Input,
  TrashIconButton
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
} from "./RouteAccess.styled";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";

export const RoutePermissions = () => {
  const [addingNewPermission, setAddingNewPermission] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    route_id: "",
    secondary_permissions: [],
    secondary_route_part: '',
    secondary_name: '',
    main_route_part: '',
  });

  const { data: secondaryRoutesData, refetch } = useQuery(
    "secondaryRoutesData",
    fetchRouteSecondary
  );
  const { data: routeData } = useQuery("routes", fetchRoute);
  const { data: positionData } = useQuery("positions", fetchPosition);

  const handleRouteChange = (event) => {
    setFormData({
      ...formData,
      route_id: event.target.value,
    });
  };

  const handleAddPosition = () => {
    setFormData({
      ...formData,
      secondary_permissions: [...formData.secondary_permissions, ""],
    });
  };

  const handleRemovePosition = (indexToRemove) => {
    setFormData({
      ...formData,
      secondary_permissions: formData.secondary_permissions.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const handlePositionChange = (event, index) => {
    const updatedPermissions = [...formData.secondary_permissions];
    updatedPermissions[index] = event.target.value;
    setFormData({
      ...formData,
      secondary_permissions: updatedPermissions,
    });
  };

  const handleSubmit = async () => {
    if (!formData.route_id || formData.secondary_permissions.length === 0) {
      toast.error("Будь ласка, виберіть маршрут та посади.");
      return;
    }

    const response = await createRouteSecondary(formData);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewPermission(false);
      refetch();
    }
  };

  const handleUpdate = async () => {
    const response = await updateRouteSecondary(formData);

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewPermission(false);
      refetch();
    }
  };

  const handleEditRoute = async (item) => {
    setFormData({ ...item });
    setIsUpdate(true);
    setAddingNewPermission(true);
  };

  const handleCancelEdit = () => {
    resetValue();
    setIsUpdate(false);
    setAddingNewPermission(false);
  };

  const handleDeleteRoute = async (id) => {
    const response = await deleteRouteSecondary(id);

    if (response?.status === 200) {
      toast.success(response.data.message);
      handleCancelEdit();
      refetch();
    }
  };

  const resetValue = () => {
    setFormData({
      id: "",
      route_id: "",
      secondary_permissions: [],
      secondary_route_part: '',
      secondary_name: ''
    });
  };

  return (
    <div>
      <Table>
        <Thead>
          <HeadTr>
            <RouteTh>Головний роут</RouteTh>
            <RouteTh>Назва другорядного роуту</RouteTh>
            <RouteTh>Назва в підменню</RouteTh>
            <RouteTh>Посади</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          {secondaryRoutesData?.length > 0 ? (
            <React.Fragment>
              {secondaryRoutesData?.map((item) => (
                <Tr key={item?.id}>
                  {addingNewPermission &&
                  isUpdated && formData?.id === item?.id ? (
                    <>
                      <RouteTd>
                        {formData.main_route_part}
                      </RouteTd>
                      <RouteTd>
                      <Input
                          type="text"
                          value={formData.secondary_route_part}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              secondary_route_part: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>
                      <RouteTd>
                      <Input
                          type="text"
                          value={formData.secondary_name}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              secondary_name: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>
                      <RouteTd>
                        {formData?.secondary_permissions?.map((position, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", marginBottom: "5px" }}
                          >
                            <Select
                              value={position?.id}
                              onChange={(event) =>
                                handlePositionChange(event, index)
                              }
                              style={{ marginRight: "5px" }}
                            >
                              <Option value="">Виберіть посаду</Option>
                              {positionData?.map((position) => (
                                <Option key={position?.id} value={position?.id}>
                                  {position?.name}
                                </Option>
                              ))}
                            </Select>
                            <TrashIconButton
                              onClick={() => handleRemovePosition(index)}
                              style={{ width: "10%" }}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </div>
                        ))}
                        <div>
                          <IconButton
                            type="button"
                            onClick={handleAddPosition}
                            style={{ width: "75%" }}
                          >
                            <MdOutlineAddCircle size="25px" />
                          </IconButton>
                        </div>
                      </RouteTd>
                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <RouteTd>{item?.main_route_part}</RouteTd>
                      <RouteTd>{item?.secondary_route_part}</RouteTd>
                      <RouteTd>{item?.secondary_name}</RouteTd>
                      <RouteTd>
                        {item?.secondary_permissions &&
                          Array.isArray(item.secondary_permissions) &&item?.secondary_permissions?.map((position) => (
                          <Text key={position?.id}>{position?.name}</Text>
                        ))}
                      </RouteTd>
                      <Td>
                        {formData?.id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton onClick={() => handleEditRoute(item)}>
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => handleDeleteRoute(item.id)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
              <Tr>
                {addingNewPermission && !isUpdated ? (
                <>
                <RouteTd>
                  
                  <Select
                    value={formData?.route_id}
                    onChange={handleRouteChange}
                  >
                    <Option value="">Виберіть маршрут</Option>
                    {routeData?.map((route) => (
                      <Option key={route?.id} value={route?.id}>
                        {route?.route_part}
                      </Option>
                    ))}
                  </Select>
                </RouteTd>
                <RouteTd>
                <Input
                        type="text"
                        value={formData.secondary_route_part}
                        onChange={(e) => {
                          const updatedFormData = {
                            ...formData,
                            secondary_route_part: e.target.value,
                          };
                          return setFormData(updatedFormData);
                        }}
                      />
                </RouteTd>
                <RouteTd>
                <Input
                        type="text"
                        value={formData.secondary_name}
                        onChange={(e) => {
                          const updatedFormData = {
                            ...formData,
                            secondary_name: e.target.value,
                          };
                          return setFormData(updatedFormData);
                        }}
                      />
                </RouteTd>
                <RouteTd>
                  {formData?.secondary_permissions?.map((position, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", marginBottom: "5px" }}
                    >
                      <Select
                        value={position?.id}
                        onChange={(event) =>
                          handlePositionChange(event, index)
                        }
                        style={{ marginRight: "5px" }}
                      >
                        <Option value="">Виберіть посаду</Option>
                        {positionData?.map((position) => (
                          <Option key={position?.id} value={position?.id}>
                            {position?.name}
                          </Option>
                        ))}
                      </Select>
                      <TrashIconButton
                        onClick={() => handleRemovePosition(index)}
                        style={{ width: "10%" }}
                      >
                        <FaTrash size="20px" />
                      </TrashIconButton>
                    </div>
                  ))}
                  <div>
                    <IconButton
                      type="button"
                      onClick={handleAddPosition}
                      style={{ width: "75%" }}
                    >
                      <MdOutlineAddCircle size="25px" />
                    </IconButton>
                  </div>
                </RouteTd>
                <Td>
                  <IconLinkContainer>
                    <IconButton onClick={handleSubmit}>
                      <FaSave size="20px" />
                    </IconButton>
                    <TrashIconButton onClick={() => handleCancelEdit()}>
                      <MdCancel size="25px" />
                    </TrashIconButton>
                  </IconLinkContainer>
                </Td>
              </>
                ) : (
                  <>
                    <RouteTd></RouteTd>
                    <RouteTd></RouteTd>
                    <RouteTd></RouteTd>
                    <RouteTd></RouteTd>
                    <Td>
                      <IconButton
                        onClick={() => setAddingNewPermission(true)}
                        style={{ width: "75%" }}
                      >
                        <MdOutlineAddCircle size="25px" />
                      </IconButton>
                    </Td>
                  </>
                )}
              </Tr>
            </React.Fragment>
          ) : (
            <Tr>
              {addingNewPermission ? (
                <>
                  <RouteTd>
                    
                    <Select
                      value={formData?.route_id}
                      onChange={handleRouteChange}
                    >
                      <Option value="">Виберіть маршрут</Option>
                      {routeData?.map((route) => (
                        <Option key={route?.id} value={route?.id}>
                          {route?.route_part}
                        </Option>
                      ))}
                    </Select>
                  </RouteTd>
                  <RouteTd>
                  <Input
                          type="text"
                          value={formData.secondary_route_part}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              secondary_route_part: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                  </RouteTd>
                  <RouteTd>
                  <Input
                          type="text"
                          value={formData.secondary_name}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              secondary_name: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                  </RouteTd>
                  <RouteTd>
                    {formData?.secondary_permissions?.map((position, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", marginBottom: "5px" }}
                      >
                        <Select
                          value={position?.id}
                          onChange={(event) =>
                            handlePositionChange(event, index)
                          }
                          style={{ marginRight: "5px" }}
                        >
                          <Option value="">Виберіть посаду</Option>
                          {positionData?.map((position) => (
                            <Option key={position?.id} value={position?.id}>
                              {position?.name}
                            </Option>
                          ))}
                        </Select>
                        <TrashIconButton
                          onClick={() => handleRemovePosition(index)}
                          style={{ width: "10%" }}
                        >
                          <FaTrash size="20px" />
                        </TrashIconButton>
                      </div>
                    ))}
                    <div>
                      <IconButton
                        type="button"
                        onClick={handleAddPosition}
                        style={{ width: "75%" }}
                      >
                        <MdOutlineAddCircle size="25px" />
                      </IconButton>
                    </div>
                  </RouteTd>
                  <Td>
                    <IconLinkContainer>
                      <IconButton onClick={handleSubmit}>
                        <FaSave size="20px" />
                      </IconButton>
                      <TrashIconButton onClick={() => handleCancelEdit()}>
                        <MdCancel size="25px" />
                      </TrashIconButton>
                    </IconLinkContainer>
                  </Td>
                </>
              ) : (
                <>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <Td>
                    <IconButton
                      onClick={() => setAddingNewPermission(true)}
                      style={{ width: "75%" }}
                    >
                      <MdOutlineAddCircle size="25px" />
                    </IconButton>
                  </Td>
                </>
              )}
            </Tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
