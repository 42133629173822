import {
  LoginContainer,
  LoginOuter,
  LoginCardBody,
  LoginLogoText,
  LoginLogoImage,
  InfoForm,
  Input,
  InputsList,
  ButtonContainer,
  InputGroup,
  InputIconContainer,
  LoginMain,
  Error,
  EyeIconContainer,
  LoginFormButton,
} from "./Login.styled";
import { LoginSchema } from "../../validations/LoginValidation";
import { useContext } from "react";
import { UserContext } from "../../components/Context/UserContext";
import logo from "../../images/logo.png";
import { Formik } from "formik";
import { MdAlternateEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { useState } from "react";
import { useNavigate } from "react-router";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../api/users";
import { encryptMiddleware } from "../../services/decryptMiddleware";

function LoginPage() {
  const navigate = useNavigate();
  const { saveToken } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    const trimmedValues = {
      email: values.email.trim(),
      password: values.password.trim(),
    };

    const response = await loginUser(trimmedValues);


    if (response && response?.message) {
      toast.error(response?.message);
      return;
    }

    if (response?.token !== null && response?.token !== undefined) {
      const newToken = await encryptMiddleware(response?.token);
      saveToken(newToken);
      return navigate("/");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <LoginMain>
      <LoginContainer>
        <LoginOuter>
          <LoginCardBody>
            <LoginLogoText>
              <LoginLogoImage width="200" height="160" src={logo} alt="DCM" />
            </LoginLogoText>
            <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => (
                <InfoForm autoComplete="off">
                  <InputsList>
                    <InputGroup>
                      <Error name="email" component="div" />
                      <InputIconContainer>
                        <MdAlternateEmail size="19" fill="green" />
                      </InputIconContainer>
                      <Input
                        placeholder="Електронна пошта"
                        type="text"
                        name="email"
                      />
                    </InputGroup>

                    <InputGroup>
                      <Error name="password" component="div" />
                      <InputIconContainer>
                        <RiLockPasswordFill size="19" fill="#d6b330" />
                      </InputIconContainer>

                      <Input
                        placeholder="Пароль"
                        type={showPassword ? "text" : "password"}
                        name="password"
                      />
                      <EyeIconContainer onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <RiEyeOffLine size="22px" color="#3098d6" />
                        ) : (
                          <RiEyeLine size="22px" color="#3098d6" />
                        )}
                      </EyeIconContainer>
                    </InputGroup>
                  </InputsList>
                  <ButtonContainer>
                    <LoginFormButton type="submit" disabled={!isValid}>
                      Увійти
                    </LoginFormButton>
                  </ButtonContainer>
                </InfoForm>
              )}
            </Formik>
          </LoginCardBody>
        </LoginOuter>
      </LoginContainer>
      <ToastContainer autoClose={4000} />
    </LoginMain>
  );
}

export default LoginPage;
