import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  Option,
  FilterSelect,
  FilterOption,
  TrashIconButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { GiCash } from "react-icons/gi";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
} from "./SalaryDayPrimaryList.styled";
import { formattedDateToDayMonthYear } from "../../../helpers/dateAndTime/formattedDateToDayMonthYear";
import { fetchUsers } from "../../../api/users";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import {
  fetchSalary,
  outcomePayment,
} from "../../../api/balance";
import { Main } from "../../GlobalComponentsAndStyles/Main";

export const SalaryDayPrimaryList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [salaryDay, setSalaryDay] = useState("");
  const [isBlocked, setIsBlocked] = useState('false');

  const { data, refetch } = useQuery(
    [
      "balance",
      {
        currentPage,
        pageLimit,
        salaryDay,
        isBlocked,
      },
    ],
    () =>
      fetchSalary({
        limit: pageLimit,
        page: currentPage,

        salaryDay,
        isBlocked,
      })
  );

  const { data: usersData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      sortBy: "full_name",
      order: "ASC",
      isBlocked: "false",
      jobTitle: "eab7c78f-1559-4bed-8a2a-67d97240f6c6",
    })
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (data) {
      setTotalPages(data?.totalPages);
    }
  }, [data, pageLimit]);

  const handleResetBalance = async (item) => {
    const newData = {
      user_id: item.user_id,
      amount: item?.total_sum - item?.salary_amount_sum,
      salary_day: salaryDay,
    };

    const response = await outcomePayment(newData);

    if (response?.status === 201) {
      toast.success(response?.message || response?.data.message);
      refetch();
    }
  };

  return (
    <Main>
      <div
        style={{
          margin: "20px",
          display: "flex",
          alignItems: "center",
          heigh: "25px",
          flexWrap: "wrap",
        }}
      >
        <label style={{ marginLeft: "10px" }}>Виберіть день виплати: </label>
        <FilterSelect
          value={salaryDay}
          onChange={(e) => {
            setSalaryDay(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть день виплати</Option>
          {data?.uniqueDays?.map((day, index) => (
            <FilterOption key={index + 10} value={day}>
              {formattedDateToDayMonthYear(day)}
            </FilterOption>
          ))}
        </FilterSelect>

        <label style={{ marginLeft: "10px" }}>Оберіть оператора: </label>
        <FilterSelect
          value={selectedEmployee}
          onChange={(e) => {
            setSelectedEmployee(e.target.value);
            setCurrentPage(1);
          }}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option value="">Виберіть оператора</Option>
          {usersData?.usersData?.map((user) => (
            <FilterOption key={user?.id} value={user?.id}>
              {user?.full_name}
            </FilterOption>
          ))}
        </FilterSelect>

        <label>Оберіть статус операторів: </label>
        <FilterSelect
          value={isBlocked}
          onChange={(e) => setIsBlocked(e.target.value)}
          style={{ marginRight: "5px", width: "200px" }}
        >
          <Option key={0} value="false">
            Не заблоковані
          </Option>
          <Option key={1} value="true">
            Заблоковані
          </Option>
        </FilterSelect>

        <PageLimitSelector
          pageLimit={pageLimit}
          onChange={handlePageLimitChange}
        />
      </div>

      <Table>
        <Thead>
          <HeadTr>
            <RouteTh style={{ width: "20px" }}>№</RouteTh>
            <RouteTh>ПІБ оператора</RouteTh>
            <RouteTh>Номер карти</RouteTh>
            <RouteTh>IBAN</RouteTh>
            <RouteTh>ЄРДПОУ</RouteTh>
            <RouteTh>Отримувач</RouteTh>
            <RouteTh style={{ width: "90px" }}>Замовленям</RouteTh>
            <RouteTh style={{ width: "70px" }}>Погодина</RouteTh>
            <RouteTh style={{ width: "70px" }}>Приведи друга</RouteTh>
            <RouteTh style={{ width: "70px" }}>Бонус</RouteTh>
            <RouteTh style={{ width: "70px" }}>Списано</RouteTh>
            <RouteTh style={{ width: "70px" }}>Всього</RouteTh>
            <RouteTh style={{ width: "70px" }}>Залишок</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          {data?.data?.length > 0 && (
            <React.Fragment>
              {data?.data?.map((item, index) => (
                <Tr key={item?.user_id}>
                  <>
                    <RouteTd style={{ width: "42px" }}>{index + 1}</RouteTd>
                    <RouteTd>{item.full_name}</RouteTd>
                    <RouteTd>{item.card}</RouteTd>
                    <RouteTd>{item.iban}</RouteTd>
                    <RouteTd>{item.identification_number}</RouteTd>
                    <RouteTd>{item.receiver_initial}</RouteTd>
                    <RouteTd style={{ width: "112px" }}>
                      {item?.order_sum} грн
                    </RouteTd>

                    <RouteTd style={{ width: "92px" }}>
                      {item?.schedule_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "92px" }}>
                      {item?.referral_program_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "92px" }}>
                      {item?.additional_bonus_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "92px" }}>
                      {item?.salary_amount_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "92px" }}>
                      {item?.total_sum} грн
                    </RouteTd>
                    <RouteTd style={{ width: "92px" }}>
                      {item?.total_sum - item?.salary_amount_sum} грн
                    </RouteTd>

                    {item?.total_sum - item?.salary_amount_sum > 0 && (
                      <Td>
                        <IconLinkContainer>
                          <TrashIconButton
                            onClick={() => handleResetBalance(item)}
                            style={{ width: "100%" }}
                          >
                            <GiCash size="20px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    )}
                  </>
                </Tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </Table>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </Main>
  );
};
