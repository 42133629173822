import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { MyEditor } from "../../MyEditor/MyEditor";
import { toast } from "react-toastify";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import {
  Input,
  ButtonContainer,
  Button,
  TrashButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  updateKnowledgeBase,
  createKnowledgeBase,
  fetchKnowledgeBase,
  deleteKnowledgeBase,
} from "../../../api/knowledgeBase";

export const KnowledgeBaseItem = () => {
  const [initialValue, setInitialValue] = useState({
    id: "",
    title: "",
    description: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const description = useRef("");
  const path = location?.pathname;

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length >= 3) {
        const getId = parts[3];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkUpdate = `/${parts[2]}` === "/change";
        const checkCreate = `/${parts[2]}` === "/create";

        if (checkUpdate) {
          setIsUpdate(true);
        }

        if (checkCreate) {
          setIsCreate(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    const fetchData = async () => {
      if (isCreate) {
        return;
      }

      if (isUpdate) {
        const response = await fetchKnowledgeBase({
          id: selectedId,
        });

        if (response?.data?.length > 0) {
          const fetchedData = response?.data;

          setInitialValue({
            ...fetchedData[0],
          });
          description.current = fetchedData[0]?.description || "";
        }
      }
    };

    fetchData();
  }, [location, isCreate, isUpdate, selectedId]);

  const maxLength = 250;

  const handlePreSubmit = () => {
    const newInitialValue = {
      id: initialValue?.id || "",
      title: initialValue?.title || "",
      description: description.current || "",
    };

    handleSubmit(newInitialValue);
  };

  const handleSubmit = async (formData) => {
    if (isCreate) {
      const response = await createKnowledgeBase(formData);

      if (response?.status === 201) {
        toast.success(response?.data?.message);
        return navigate("/knowledge-base/primary-list");
      }
    }

    if (isUpdate) {
      const response = await updateKnowledgeBase(formData);

      if (response?.status === 200) {
        toast.success(response?.data?.message);
        return navigate(`/knowledge-base/primary-list/${initialValue?.id}`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/knowledge-base/primary-list");
  };

  const handleDelete = async (id) => {
    const response = await deleteKnowledgeBase(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return navigate("/knowledge-base/primary-list");
    }
  };

  return (
    <div>
      <Main>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Input
              style={{ width: "70%", marginRight: "10px" }}
              value={initialValue?.title}
              onChange={(e) => {
                const updatedValue = {
                  ...initialValue,
                  title: e.target.value,
                };
                if (updatedValue?.title?.length <= maxLength) {
                  setInitialValue(updatedValue);
                }
              }}
              placeholder="Введіть назву:"
              maxLength={maxLength}
              required
            />
            <p style={{ margin: "0" }}>
              Залишилося символів: {maxLength - initialValue?.title?.length}/
              {maxLength}
            </p>
          </div>

          <div style={{ width: "100%", minHeight: "300px" }}>
            <MyEditor
              value={description.current || ""}
              onChange={(newContent) => (description.current = newContent)}
            />
          </div>
        </div>

        {isCreate && (
          <ButtonContainer>
            <Button onClick={() => handlePreSubmit()}>Зберегти</Button>
            <TrashButton onClick={() => handleCancelClick()}>
              Скасувати
            </TrashButton>
          </ButtonContainer>
        )}
        {!isCreate && (
          <ButtonContainer>
            <Button onClick={() => handlePreSubmit()}>Внести зміни</Button>
            <TrashButton
              onClick={() => {
                handleDelete(initialValue.id);
              }}
            >
              Видалити
            </TrashButton>
          </ButtonContainer>
        )}
      </Main>
    </div>
  );
};
