import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchOrderStatus = async () => {
  try {
    const response = await makeRequestSQL.get("/order/order-status");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOrderMe = async ({
  page,
  limit,
  sortBy,
  order,
  status,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/order/me", {
      params: {
        page,
        limit,
        sortBy,
        order,
        status,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOrder = async ({
  page,
  limit,
  search,
  sortBy,
  order,
  employee,
  status,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/order/", {
      params: {
        page,
        limit,
        search,
        sortBy,
        order,
        employee,
        status,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createOrder = async (data) => {
  try {
    const response = await makeRequestSQL.post("/order", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateOrder = async (data) => {
  try {
    const response = await makeRequestSQL.put("/order", data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const deleteOrder = async (id) => {
  try {
    const response = await makeRequestSQL.delete(`/order`, {
      params: {
        id,
      },
    });
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
