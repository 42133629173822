import React, { useContext, useState, useEffect } from "react";
import {
  TopBarLogoutContainer,
  TopBarLogoutButton,
  SharedLayoutContainer,
  ImageContainer,
  NavList,
  NavigationLink,
  ExitDoor,
  SecondaryRouteContainer,
  SecondaryItem,
  SecondaryNavigationLink,
  SharedLayoutListItem,
  TopContainer,
  UnreadText
} from "./SharedLayout.styled";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { UserContext } from "../Context/UserContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logoutUser } from "../../api/users";
import logo from "../../images/logo.png";
import { toast } from "react-toastify";
import { fetchPermission } from "../../api/settings";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { NavContext } from "../Context/NavContext";
import { fetchMyNews } from "../../api/news";

export const SharedLayout = () => {
  const navigate = useNavigate();
  const { clearToken } = useContext(UserContext);
  const { menuVisible, toggleMenu } = useContext(NavContext);
  const [openRoutes, setOpenRoutes] = useState({});
  const [unreadNewsCount, setUnreadNewsCount] = useState(0);

  const handleLogout = async () => {
    const response = await logoutUser();

    if (response?.status === 200) {
      toast.success(response.data.message);
      clearToken("");
      navigate("/login");
    }
  };

  const { data: menuData } = useQuery("menuData", fetchPermission, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: 1,
    onError: (error) => {
      console.error("Error fetching data", error);
    },
  });

  const { data: news } = useQuery("news", fetchMyNews);

  useEffect(() => {
    if (news && news?.data?.length > 0) {
      const unreadNews = news?.data?.filter((item) => !item?.is_approve);
      setUnreadNewsCount(unreadNews?.length);
    }
  }, [news]);

  const handleMainRouteClick = (mainRoute) => {
    setOpenRoutes((prevState) => ({
      ...prevState,
      [mainRoute]: !prevState[mainRoute],
    }));
  };

  const newsRoute = menuData?.routes?.find(
    (route) => route?.mainRoute?.name === "Новини"
  );
  const hasNoSecondaryRoutes =
    newsRoute?.secondaryRoutes?.length === 1 ||
    newsRoute?.secondaryRoutes === undefined;

  return (
    <SharedLayoutContainer>
      <NavList isVisible={menuVisible}>
        {menuData?.routes?.map((route) => (
          <SharedLayoutListItem key={route?.mainRoute?.id}>
            <NavigationLink
              to={
                route?.secondaryRoutes?.length === 1
                  ? `${route?.mainRoute?.route_part}${route?.secondaryRoutes[0]?.route_part}`
                  : `${route.mainRoute.route_part}`
              }
              onClick={(e) => {
                if (route?.secondaryRoutes?.length > 1) {
                  e.preventDefault();
                  handleMainRouteClick(route?.mainRoute?.route_part);
                }
              }}
            >
              {route?.secondaryRoutes?.length === 1
                ? route?.secondaryRoutes[0]?.name
                : route?.mainRoute?.name}
              {route?.mainRoute?.name === "Новини" &&  (
                unreadNewsCount > 0 && <UnreadText newsLength={unreadNewsCount}>{unreadNewsCount}</UnreadText>
              )}
            </NavigationLink>

            {openRoutes[route.mainRoute.route_part] &&
              route?.secondaryRoutes?.length > 1 && (
                <SecondaryRouteContainer>
                  {route?.secondaryRoutes?.map((secondaryRoute) => (
                    <SecondaryItem key={secondaryRoute.id}>
                      <SecondaryNavigationLink
                        to={`${route.mainRoute.route_part}${secondaryRoute.route_part}`}
                      >
                        {secondaryRoute.name}
                      </SecondaryNavigationLink>
                    </SecondaryItem>
                  ))}
                </SecondaryRouteContainer>
              )}
          </SharedLayoutListItem>
        ))}
      </NavList>

      <TopContainer isVisible={menuVisible}>
        <TopBarLogoutButton onClick={toggleMenu}>
          {menuVisible ? (
            <FaAnglesLeft size="24px" style={{ fill: "#eeee40" }} />
          ) : (
            <FaAnglesRight size="24px" style={{ fill: "#eeee40" }} />
          )}
        </TopBarLogoutButton>

        <ImageContainer>
          <a href="https://www.dcm-crm.com.ua/">
            <img src={logo} width="100%" height="100%" alt="dcm" />
          </a>
        </ImageContainer>

        <TopBarLogoutContainer>
          <TopBarLogoutButton onClick={handleLogout}>
            <ExitDoor size="36px" />
          </TopBarLogoutButton>
        </TopBarLogoutContainer>
      </TopContainer>

      <ToastContainer autoClose={4000} />
    </SharedLayoutContainer>
  );
};
