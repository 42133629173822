import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createRoute,
  fetchRoute,
  deleteRoute,
  updateRoute,
  fetchPosition,
} from "../../../api/settings";
import {
  Table,
  Thead,
  Tr,
  HeadTr,
  Td,
  Th,
  IconButton,
  Input,
  Text,
  Select,
  Option,
  TrashIconButton
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import {
  RouteTh,
  RouteTd,
  IconLinkContainer,
} from "./MainRoutes.styled";
import { FaTrash, FaEdit, FaSave } from "react-icons/fa";
import { MdCancel, MdOutlineAddCircle } from "react-icons/md";

export const MainRoutes = () => {
  const [addingNewRoute, setAddingNewRoute] = useState(false);
  const [isUpdated, setIsUpdate] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    route_part: "",
    name: "",
    permissions: [],
  });

  const handleCancelEdit = () => {
    resetValue();
    setAddingNewRoute(false);
  };

  const handleEditRoute = async (item) => {
    setFormData({ ...item });
    setIsUpdate(true);
    setAddingNewRoute(true);
  };

  const { data: routeData, refetch } = useQuery("routeData", fetchRoute);
  const { data: positionData } = useQuery("positions", fetchPosition);

  const handleDeleteRoute = async (positionId) => {
    const response = await deleteRoute(positionId);

    if (response?.status === 200) {
      toast.success(response.data.message);
      handleCancelEdit();
      refetch();
    }
  };

  const handlePositionChange = (event, index) => {
    const updatedPermissions = [...formData.permissions];
    updatedPermissions[index] = event.target.value;
    setFormData({
      ...formData,
      permissions: updatedPermissions,
    });
  };

  const handleAddPosition = () => {
    setFormData({
      ...formData,
      permissions: [...formData?.permissions, ""],
    });
  };

  const handleRemovePosition = (indexToRemove) => {
    setFormData({
      ...formData,
      permissions: formData?.permissions?.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const resetValue = () => {
    setFormData({
      id: "",
      route_part: "",
      name: "",
      permissions: [],
    });
  };

  const handleUpdate = async () => {
    const response = await updateRoute(formData);

    if (response?.status === 200) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  const handleSubmit = async () => {
    if (!formData?.route_part || formData?.permissions?.length === 0) {
      toast.error("Будь ласка, виберіть маршрут та посади.");
      return;
    }

    const response = await createRoute(formData);

    if (response?.status === 201) {
      toast.success(response.data.message);
      resetValue();
      setIsUpdate(false);
      setAddingNewRoute(false);
      refetch();
    }
  };

  return (
    <div>
      <Table>
        <Thead>
          <HeadTr>
            <RouteTh>Роут</RouteTh>
            <RouteTh>Назва в меню</RouteTh>
            <RouteTh>Посади</RouteTh>
            <Th>Панeль управліня</Th>
          </HeadTr>
        </Thead>
        <tbody>
          {routeData?.length > 0 ? (
            <React.Fragment>
              {routeData?.map((item) => (
                <Tr key={item?.id}>
                  {addingNewRoute &&
                  isUpdated &&
                  formData?.id === item?.id ? (
                    <>
                      <RouteTd>
                        <Input
                          type="text"
                          value={formData.route_part}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              route_part: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>
                      <RouteTd>
                        <Input
                          type="text"
                          value={formData.name}
                          onChange={(e) => {
                            const updatedFormData = {
                              ...formData,
                              name: e.target.value,
                            };
                            return setFormData(updatedFormData);
                          }}
                        />
                      </RouteTd>
                      <RouteTd>
                        {formData?.permissions &&
                          Array.isArray(formData.permissions) &&
                          formData.permissions.map((position, index) => (
                            <div
                              key={index}
                              style={{ display: "flex", marginBottom: "5px" }}
                            >
                              <Select
                                value={position?.id}
                                onChange={(event) =>
                                  handlePositionChange(event, index)
                                }
                                style={{ marginRight: "5px" }}
                              >
                                <Option value="">Виберіть посаду</Option>
                                {positionData?.map((position) => (
                                  <Option
                                    key={position?.id}
                                    value={position?.id}
                                  >
                                    {position?.name}
                                  </Option>
                                ))}
                              </Select>
                              <TrashIconButton
                                onClick={() => handleRemovePosition(index)}
                                style={{ width: "10%" }}
                              >
                                <FaTrash size="20px" />
                              </TrashIconButton>
                            </div>
                          ))}
                        <div>
                          <IconButton
                            type="button"
                            onClick={handleAddPosition}
                            style={{ width: "75%" }}
                          >
                            <MdOutlineAddCircle size="25px" />
                          </IconButton>
                        </div>
                      </RouteTd>
                      <Td>
                        <IconLinkContainer>
                          <IconButton onClick={handleUpdate}>
                            <FaSave size="20px" />
                          </IconButton>
                          <TrashIconButton onClick={() => handleCancelEdit()}>
                            <MdCancel size="25px" />
                          </TrashIconButton>
                        </IconLinkContainer>
                      </Td>
                    </>
                  ) : (
                    <>
                      <RouteTd>{item?.route_part}</RouteTd>
                      <RouteTd>{item?.name}</RouteTd>
                      <RouteTd>
                        {item?.permissions &&
                          Array.isArray(item.permissions) &&
                          item?.permissions?.map((position) => (
                            <Text key={position?.id}>{position?.name}</Text>
                          ))}
                      </RouteTd>
                      <Td>
                        {formData?.route_id !== item?.id && (
                          <IconLinkContainer>
                            <IconButton onClick={() => handleEditRoute(item)}>
                              <FaEdit size="20px" />
                            </IconButton>
                            <TrashIconButton
                              onClick={() => handleDeleteRoute(item.id)}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </IconLinkContainer>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
              <Tr>
                {addingNewRoute && !isUpdated ? (
                  <>
                    <RouteTd>
                      <Input
                        type="text"
                        value={formData.route_part}
                        onChange={(e) => {
                          const updatedFormData = {
                            ...formData,
                            route_part: e.target.value,
                          };
                          return setFormData(updatedFormData);
                        }}
                      />
                    </RouteTd>
                    <RouteTd>
                      <Input
                        type="text"
                        value={formData.name}
                        onChange={(e) => {
                          const updatedFormData = {
                            ...formData,
                            name: e.target.value,
                          };
                          return setFormData(updatedFormData);
                        }}
                      />
                    </RouteTd>
                    <RouteTd>
                      {formData?.permissions &&
                        Array.isArray(formData.permissions) &&
                        formData.permissions.map((position, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", marginBottom: "5px" }}
                          >
                            <Select
                              value={position?.id}
                              onChange={(event) =>
                                handlePositionChange(event, index)
                              }
                              style={{ marginRight: "5px" }}
                            >
                              <Option value="">Виберіть посаду</Option>
                              {positionData?.map((position) => (
                                <Option key={position?.id} value={position?.id}>
                                  {position?.name}
                                </Option>
                              ))}
                            </Select>
                            <TrashIconButton
                              onClick={() => handleRemovePosition(index)}
                              style={{ width: "10%" }}
                            >
                              <FaTrash size="20px" />
                            </TrashIconButton>
                          </div>
                        ))}
                      <div>
                        <IconButton
                          type="button"
                          onClick={handleAddPosition}
                          style={{ width: "75%" }}
                        >
                          <MdOutlineAddCircle size="25px" />
                        </IconButton>
                      </div>
                    </RouteTd>
                    <Td>
                      <IconLinkContainer>
                        <IconButton onClick={handleSubmit}>
                          <FaSave size="20px" />
                        </IconButton>
                        <TrashIconButton onClick={() => handleCancelEdit()}>
                          <MdCancel size="25px" />
                        </TrashIconButton>
                      </IconLinkContainer>
                    </Td>
                  </>
                ) : (
                  <>
                    <RouteTd></RouteTd>
                    <RouteTd></RouteTd>
                    <RouteTd></RouteTd>
                    <Td>
                      <IconButton
                        onClick={() => setAddingNewRoute(true)}
                        style={{ width: "75%" }}
                      >
                        <MdOutlineAddCircle size="25px" />
                      </IconButton>
                    </Td>
                  </>
                )}
              </Tr>
            </React.Fragment>
          ) : (
            <Tr>
              {addingNewRoute ? (
                <>
                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.route_part}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          route_part: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>

                  <RouteTd>
                    <Input
                      type="text"
                      value={formData.name}
                      onChange={(e) => {
                        const updatedFormData = {
                          ...formData,
                          name: e.target.value,
                        };
                        return setFormData(updatedFormData);
                      }}
                    />
                  </RouteTd>
                  <RouteTd>
                    {formData?.permissions &&
                      Array.isArray(formData.permissions) &&
                      formData.permissions.map((position, index) => (
                        <div
                          key={index}
                          style={{ display: "flex", marginBottom: "5px" }}
                        >
                          <Select
                            value={position?.id}
                            onChange={(event) =>
                              handlePositionChange(event, index)
                            }
                            style={{ marginRight: "5px" }}
                          >
                            <Option value="">Виберіть посаду</Option>
                            {positionData?.map((position) => (
                              <Option key={position?.id} value={position?.id}>
                                {position?.name}
                              </Option>
                            ))}
                          </Select>
                          <TrashIconButton
                            onClick={() => handleRemovePosition(index)}
                            style={{ width: "10%" }}
                          >
                            <FaTrash size="20px" />
                          </TrashIconButton>
                        </div>
                      ))}
                    <div>
                      <IconButton
                        type="button"
                        onClick={handleAddPosition}
                        style={{ width: "75%" }}
                      >
                        <MdOutlineAddCircle size="25px" />
                      </IconButton>
                    </div>
                  </RouteTd>
                  <Td>
                    <IconLinkContainer>
                      <IconButton onClick={handleSubmit}>
                        <FaSave size="20px" />
                      </IconButton>
                      <TrashIconButton onClick={() => handleCancelEdit()}>
                        <MdCancel size="25px" />
                      </TrashIconButton>
                    </IconLinkContainer>
                  </Td>
                </>
              ) : (
                <>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <RouteTd></RouteTd>
                  <Td>
                    <IconButton
                      onClick={() => setAddingNewRoute(true)}
                      style={{ width: "75%" }}
                    >
                      <MdOutlineAddCircle size="25px" />
                    </IconButton>
                  </Td>
                </>
              )}
            </Tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
