import { useState, useEffect } from "react";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { Outlet, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  ProductSection,
  ProductNavigationList,
  ProductNavigationItem,
  ProductNavigationButton,
  ProductCardList,
  ImageContainer,
  ProductImage,
  ProductCardItem,
  ProductTextContainer,
  ProductTitle,
  ProductLabel,
  ProductPrice,
  ActiveCategoryButton,
  ProductLink,
} from "./ProductsList.styled";
import {
  fetchProduct,
  fetchProductCategory,
  deleteProduct,
} from "../../../api/product";
import { Pagination } from "../../Pagination/Pagination";
import { PageLimitSelector } from "../../PageLimitSelector/PageLimitSelector";
import { Search } from "../../SearchComponent/SearchComponent";
import {
  IconButton,
  TrashIconButton,
  ButtonContainer,
  FilterSelect,
  FilterOption,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { FaTrash, FaEdit } from "react-icons/fa";
import { ScrollToTopButton } from "../../ScrollToTopButton/ScrollToTopButton";

export const ProductList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    sessionStorage.getItem("selectedCategoryId") || ""
  );
  const [isPrimary, setIsPrimary] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const location = useLocation();
  const path = location?.pathname;

  const { data: productData, refetch } = useQuery(
    ["productData", selectedCategoryId, isAvailable],
    () =>
      fetchProduct({
        category_id: selectedCategoryId,
        sortBy: "title",
        is_available: isAvailable,
      }),
    {
      staleTime: 5 * 60 * 1000, 
      cacheTime: 10 * 60 * 1000, 
      retry: 1, 
      onError: (error) => {
        console.error("Error fetching data:", error);
      },
    }
  );

  const { data: productCategory } = useQuery(["productCategory"], () =>
    fetchProductCategory()
  );

  const filteredProducts = productData?.data?.filter((item) => {
    return item?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase());
  });

  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
    sessionStorage.setItem("selectedCategoryId", categoryId);
    setCurrentPage(1);
  };

  const handlePageLimitChange = (newPageLimit) => {
    setPageLimit(newPageLimit);
    setCurrentPage(1);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");
      if (parts?.length > 2) {
        const checkPrimary = `/${parts[2]}` === "/primary-list";
        if (checkPrimary) {
          setIsPrimary(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    if (filteredProducts) {
      const totalItems = filteredProducts?.length;
      const calculatedTotalPages = Math.ceil(totalItems / pageLimit);
      setTotalPages(calculatedTotalPages);

      if (currentPage > calculatedTotalPages) {
        setCurrentPage(1);
      }
    }
  }, [filteredProducts, pageLimit, currentPage]);

  const startIndex = (Number(currentPage) - 1) * Number(pageLimit);
  const endIndex = Number(startIndex) + Number(pageLimit);
  const paginatedProducts = filteredProducts?.slice(startIndex, endIndex);

  const handleSearch = (event) => {
    event?.preventDefault();
    refetch();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDelete = async (id) => {
    const response = await deleteProduct(id);
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      refetch();
    }
  };

  return (
    <div>
      <Main>
        <ProductSection>
          <ProductNavigationList>
            <ProductNavigationItem key="all">
              {selectedCategoryId === "" ? (
                <ActiveCategoryButton
                  type="button"
                  onClick={() => handleCategoryChange("")}
                >
                  Всі категорії
                </ActiveCategoryButton>
              ) : (
                <ProductNavigationButton
                  type="button"
                  onClick={() => handleCategoryChange("")}
                >
                  Всі категорії
                </ProductNavigationButton>
              )}
            </ProductNavigationItem>
            {Array.isArray(productCategory) &&
              productCategory?.map((category) => (
                <ProductNavigationItem key={category.id}>
                  {selectedCategoryId === category.id ? (
                    <ActiveCategoryButton
                      type="button"
                      onClick={() => handleCategoryChange(category.id)}
                    >
                      {category.title}
                    </ActiveCategoryButton>
                  ) : (
                    <ProductNavigationButton
                      type="button"
                      onClick={() => handleCategoryChange(category.id)}
                    >
                      {category.title}
                    </ProductNavigationButton>
                  )}
                </ProductNavigationItem>
              ))}
          </ProductNavigationList>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <Search
              inputValue={searchQuery}
              handleSearch={handleSearch}
              handleInputChange={handleInputChange}
              handleKeyPress={handleKeyPress}
            />
            <PageLimitSelector
              pageLimit={pageLimit}
              onChange={handlePageLimitChange}
            />

            {isPrimary && (
              <div style={{ marginLeft: "20px" }}>
                <label>Оберіть наявність товару: </label>
                <FilterSelect
                  value={isAvailable}
                  onChange={(e) => setIsAvailable(e.target.value)}
                  style={{ width: "200px" }}
                >
                  <FilterOption key={0} value="false">
                    Немає в наявності
                  </FilterOption>
                  <FilterOption key={1} value="true">
                    Є в наявності
                  </FilterOption>
                </FilterSelect>
              </div>
            )}
          </div>

          <ProductCardList>
            {productData?.data?.length > 0 ? (
              <>
                {paginatedProducts?.map((item) => (
                  <ProductCardItem key={item.id}>
                    <ProductLink
                      to={
                        isPrimary
                          ? `/product/primary-list/${item.id}`
                          : `/product/list/${item.id}`
                      }
                    >
                      <ImageContainer>
                        <ProductImage
                          src={item.image_url}
                          alt="фото товару"
                          width="370"
                          height="294"
                        />
                      </ImageContainer>
                      <ProductTextContainer>
                        <div>
                          <ProductTitle>{item.title}</ProductTitle>
                          <ProductLabel>{item.category_title}</ProductLabel>
                          <ProductPrice>
                            <span style={{ color: "red", textDecoration: "" }}>
                              Без знижки -{" "}
                              {((item.price + 500) * 1.71)?.toFixed()} грн
                            </span>
                            <span style={{ color: "green", marginLeft: '50px' }}>
                            Зі знижкою - {item.price + 500} грн
                            </span>
                          </ProductPrice>
                          <ProductLabel>{item.small_description}</ProductLabel>
                        </div>
                      </ProductTextContainer>
                    </ProductLink>
                    {isPrimary && (
                      <ButtonContainer>
                        <IconButton to={`/product/change/${item.id}`}>
                          <FaEdit size={"24px"} />
                        </IconButton>
                        <TrashIconButton onClick={() => handleDelete(item.id)}>
                          <FaTrash size={"24px"} />
                        </TrashIconButton>
                      </ButtonContainer>
                    )}
                  </ProductCardItem>
                ))}
              </>
            ) : (
              <div>На жаль у вас поки немає товарів в обраній категорії</div>
            )}
          </ProductCardList>
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </ProductSection>
      </Main>
      <ScrollToTopButton />
      <Outlet />
    </div>
  );
};
