import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchReferralProgramStatuses = async ({
    sortBy,
    order,
    searchQuery,
    currentPage,
    pageLimit,
    id
  }) => {
    try {
      const response = await makeRequestSQL.get('/referral-program-statuses/', {
        params: {
          sortBy,
          order,
          search: searchQuery,
          page: currentPage,
          limit: pageLimit,
          id
        },
      });
      const decryptedData = await  decryptMiddleware(response.data);
  
      return decryptedData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const updateReferralProgramStatuses = async data => {
    try {
      const response = await makeRequestSQL.put('/referral-program-statuses/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const createReferralProgramStatuses = async data => {
    try {
      const response = await makeRequestSQL.post('/referral-program-statuses/', data);
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };
  
  export const deleteReferralProgramStatuses = async id => {
    try {
      const response = await makeRequestSQL.delete('/referral-program-statuses/', {
        params: {
          id,
        },
      });
      return response;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      toast.error(errorMessage);
    }
  };