import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { MyEditor } from "../../MyEditor/MyEditor";
import { toast } from "react-toastify";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import {
  createNews,
  updateNews,
  fetchNews,
  deleteNews,
} from "../../../api/news";
import {
  Input,
  ButtonContainer,
  Button,
  TrashButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { useQuery } from "react-query";
import { fetchPosition } from "../../../api/settings";

export const NewsItem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCreate, setIsCreate] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [news, setNews] = useState({
    id: "",
    title: "",
    description: "",
    job_titles: [], 
    status_message_id: 1
  });

  const path = location?.pathname;
  const description = useRef("");

  const { data: positions, isLoading: positionsLoading } = useQuery(
    "fetchPositions",
    fetchPosition
  );

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length >= 3) {
        const getId = parts[3];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkUpdate = `/${parts[2]}` === "/change";
        const checkCreate = `/${parts[2]}` === "/create";

        if (checkUpdate) {
          setIsUpdate(true);
        }

        if (checkCreate) {
          setIsCreate(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    const fetchNewsData = async () => {
      if (isCreate) return;

      if (isUpdate) {
        const response = await fetchNews({ id: selectedId });

        if (response?.data?.length > 0) {
          const fetchedNews = response?.data;

          setNews({
            ...fetchedNews[0],
          });
          description.current = fetchedNews[0]?.description || "";
        }
      }
    };

    fetchNewsData();
  }, [location, isCreate, selectedId, isUpdate]);

  const maxLength = 250;

  const handlePreSubmit = () => {
    const newNews = {
      id: news?.id || "",
      title: news?.title || "",
      description: description.current || "",
      job_titles: news?.job_titles || [], 
      status_message_id: 1
    };

    handleSubmit(newNews);
  };

  const handleSubmit = async (formData) => {
    if (isCreate) {
      const response = await createNews(formData);

      if (response?.status === 201) {
        toast.success(response?.data?.message);
        return navigate("/news/primary-list");
      }
    }

    if (isUpdate) {
      const response = await updateNews(formData);

      if (response?.status === 200) {
        toast.success(response?.data?.message);
        return navigate(`/news/primary-list`);
      }
    }
  };

  const handleCancelClick = () => {
    return navigate("/news/primary-list");
  };

  const handleDelete = async (id) => {
    const response = await deleteNews(id);

    if (response?.status === 200) {
      toast.success(response?.data?.message);
      return navigate("/news/primary-list");
    }
  };

  const handleJobTitleChange = (position) => {
    setNews((prevNews) => {
      const isAlreadySelected = prevNews.job_titles.some(
        (job) => job.id === position.id
      );

      const updatedJobTitles = isAlreadySelected
        ? prevNews.job_titles.filter((job) => job.id !== position.id) 
        : [...prevNews.job_titles, { id: position.id, title: position.name }]; 

      return { ...prevNews, job_titles: updatedJobTitles };
    });
  };

  return (
    <Main>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Input
            style={{ width: "70%", marginRight: "10px" }}
            value={news?.title}
            type="text"
            onChange={(e) => {
              const updatedNews = {
                ...news,
                title: e.target.value,
              };
              if (updatedNews?.title?.length <= maxLength) {
                setNews(updatedNews);
              }
            }}
            placeholder="Введіть заголовок"
            maxLength={maxLength}
            required
          />
          <p style={{ margin: "0" }}>
            Залишилося символів: {maxLength - news?.title?.length}/{maxLength}
          </p>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <h4>Оберіть посади:</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "10px",
            }}
          >
            {positionsLoading ? (
              <p>Завантаження посад...</p>
            ) : (
              positions?.map((position) => (
                <div
                  key={position.id}
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id={`job-title-${position.id}`}
                    checked={news.job_titles.some(
                      (job) => job.id === position.id
                    )}
                    onChange={() => handleJobTitleChange(position)}
                  />
                  <label htmlFor={`job-title-${position.id}`}>
                    {position.name}
                  </label>
                </div>
              ))
            )}
          </div>
        </div>

        <div style={{ width: "100%", minHeight: "300px" }}>
          <MyEditor
            value={description.current || ""}
            onChange={(newContent) => (description.current = newContent)}
          />
        </div>
      </div>

      {isCreate && (
        <ButtonContainer>
          <Button onClick={() => handlePreSubmit()}>Зберегти</Button>
          <TrashButton onClick={() => handleCancelClick()}>
            Скасувати
          </TrashButton>
        </ButtonContainer>
      )}
      {isUpdate && (
        <ButtonContainer>
          <Button onClick={() => handlePreSubmit()}>Внести зміни</Button>
          <TrashButton
            onClick={() => {
              handleDelete(news.id);
            }}
          >
            Видалити
          </TrashButton>
        </ButtonContainer>
      )}
    </Main>
  );
};
