import { makeRequestSQL, clearAuthHeader } from "./api";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchUsers = async ({
  id,
  jobTitle,
  sortBy,
  order,
  isBlocked,
  search,
  page,
  limit,
  employeeStatus,
  firedStatus
}) => {
  try {
    const response = await makeRequestSQL.get("/user", {
      params: {
        id,
        jobTitle,
        sortBy,
        order,
        search,
        isBlocked,
        page,
        limit,
        employeeStatus,
        firedStatus
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchCurrentUser = async () => {
  try {
    const response = await makeRequestSQL.get("/user/me");
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData?.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOnlineUsers = async () => {
  try {
    const response = await makeRequestSQL.get(`/user/online-status`);
    const decryptedData = await decryptMiddleware(response.data);
    return decryptedData?.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

const fetchIPAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    const { ip } = response.data;

    return ip;
  } catch (error) {
    return null;
  }
};

export const loginUser = async (credentials) => {
  try {
    const ip = await fetchIPAddress();
    const requestData = { ...credentials, ip };

    const response = await makeRequestSQL.post("/auth/login", requestData);

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const logoutUser = async () => {
  try {
    const response = await makeRequestSQL.put("/auth/logout");
    clearAuthHeader();

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateUser = async (data) => {
  try {
    const response = await makeRequestSQL.put(`/user`, data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createUser = async (data) => {
  try {
    const response = await makeRequestSQL.post("/user", data);

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
