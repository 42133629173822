import {
  Option,
  Input,
  InfoForm,
  InputsList,
  InputGroup,
  InputIconContainer,
  Select,
  Error,
  EyeIconContainer,
} from "./UsersItem.styled";
import {
  ButtonContainer,
  Button,
  TrashButton,
} from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import { MdAlternateEmail } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import {
  RiLockPasswordFill,
  RiEyeLine,
  RiEyeOffLine,
  RiUserReceived2Fill,
  RiGroup2Line 
} from "react-icons/ri";
import { HiIdentification } from "react-icons/hi2";
import {
  FaUser,
  FaCity,
  FaAddressCard,
  FaPhoneAlt,
  FaTelegramPlane,
  FaBirthdayCake,
  FaBriefcase,
} from "react-icons/fa";
import {
  FaCcMastercard,
  FaCommentDots,
  FaUserTie,
  FaCalendarDays,
} from "react-icons/fa6";
import { CiBank } from "react-icons/ci";
import { Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { Main } from "../../GlobalComponentsAndStyles/Main";
import { fetchPosition } from "../../../api/settings";
import {
  createUser,
  fetchUsers,
  updateUser,
  fetchCurrentUser,
} from "../../../api/users";
import { formatDateToInput } from "../../../helpers/dateAndTime/formatDate";
import { getFiredEmployeeStatuses } from "../../../api/firedEmployeeStatuses";
import { getEmployeeStatuses } from "../../../api/employeeStatus";

export const UserItem = () => {
  const location = useLocation();
  const path = location?.pathname;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const [initialValues, setInitialValues] = useState({
    id: "",
    email: "",
    password: "",
    full_name: "",
    city: "",
    address: "",
    phone_number: "",
    telegram: "",
    birthday: "",
    id_job_title: "",
    comment: "",
    isBlocked: false,
    fired_status_id: "",
    inviter_id: "",
    education_day: "",
    iban: "",
    identification_number: "",
    receiver_initial: "",
    employee_status_id: "",
  });
  
  const { data } = useQuery("position", fetchPosition);

  const { data: userData } = useQuery(["getAllEmployees"], () =>
    fetchUsers({
      isBlocked: "false",
      sortBy: 'full_name'
    })
  );

  const { data: firedEmployeeStatusesData } = useQuery(
    "firedEmployeeStatuses",
    getFiredEmployeeStatuses
  );

  const { data: employeeStatusesData } = useQuery(
    "getEmployeeStatuses",
    getEmployeeStatuses
  );

  useEffect(() => {
    if (path && typeof path === "string") {
      const parts = path.split("/");

      if (parts?.length >= 3) {
        const getId = parts[3];
        setSelectedId(getId);
      }

      if (parts?.length > 2) {
        const checkUpdate = `/${parts[2]}` === "/change";
        const checkCreate = `/${parts[2]}` === "/create";
        const checkCurrentUser = `/${parts[2]}` === "/current-user";

        if (checkUpdate) {
          setIsUpdate(true);
        }

        if (checkCreate) {
          setIsCreated(true);
        }

        if (checkCurrentUser) {
          setIsCurrentUser(true);
          setIsUpdate(true);
        }
      }
    }
  }, [path]);

  useEffect(() => {
    const fetchClaim = async () => {
      if (isCreated) return;
  
      if (isUpdate) {
        const response = await fetchUsers({ id: selectedId });
  
        if (response?.usersData?.length > 0) {
          const fetchData = response?.usersData[0];
          const updatedInitialValue = {};
  
          Object.entries(fetchData).forEach(([key, value]) => {
            updatedInitialValue[key] = value ?? ""; 
          });
  
          updatedInitialValue.birthday = formatDateToInput(fetchData.birthday ?? "");
          updatedInitialValue.education_day = formatDateToInput(fetchData.education_day ?? "");
  
          setInitialValues(updatedInitialValue);
        }
      }
  
      if (isCurrentUser) {
        const response = await fetchCurrentUser();
  
        if (response?.email) {
          const updatedInitialValue = {};
  
          Object.entries(response).forEach(([key, value]) => {
            updatedInitialValue[key] = value ?? "";
          });
  
          updatedInitialValue.birthday = formatDateToInput(response.birthday ?? "");
          updatedInitialValue.education_day = formatDateToInput(response.education_day ?? "");
  
          setInitialValues(updatedInitialValue);
        }
      }
    };
  
    fetchClaim();
  }, [location, isCreated, selectedId, isUpdate, isCurrentUser]);
  

  const handleSubmit = async (values, resetForm) => {
    setLoading(true);

    if (isCreated) {
      const response = await createUser(values);
      if (response?.status === 201) {
        toast.success(response?.data?.message);
        resetForm();
        setLoading(false);
        return navigate("/user/list");
      }
    }

    if (isUpdate) {
      const response = await updateUser({ ...values, id: selectedId });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        resetForm();
        setLoading(false);
        return navigate("/user/list");
      }
    }

    if (isCurrentUser) {
      const response = await updateUser({ ...values, id: initialValues.id });
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        resetForm();
        setLoading(false);
        return navigate("/user/list");
      }
    }
  };

  const handleCancel = () => {
    return navigate("/user/list");
  };

  const handleBlock = async () => {
    const reverseIsBlocked =
      initialValues.isBlocked === "true" ? "false" : "true";

    const response = await updateUser({
      id: initialValues.id,
      isBlocked: reverseIsBlocked,
    });
    if (response?.status === 200) {
      toast.success(response?.data?.message);
      const updatedFormData = {
        ...initialValues,
        isBlocked: initialValues.isBlocked === "true" ? "false" : "true",
      };
      return setInitialValues(updatedFormData);
    }
  };

  return (
    <Main>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "top",
            height: "5vh",
          }}
        >
          <ClipLoader color="#3098d6" loading={loading} size={30} />
        </div>
      )}
      <Formik
        key={initialValues.id}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({ isValid }) => (
          <InfoForm autoComplete="off">
            <InputsList>
              <InputGroup>
                <Error name="email" component="div" />
                <InputIconContainer>
                  <MdAlternateEmail size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть емеіл"
                  type="email"
                  name="email"
                  disabled={isCurrentUser}
                />
              </InputGroup>

              <InputGroup>
                <Error name="password" component="div" />
                <InputIconContainer>
                  <RiLockPasswordFill size="22" fill="#d6b330" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть пароль"
                  type={showPassword ? "text" : "password"}
                  name="password"
                />
                <EyeIconContainer onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <RiEyeOffLine size="22px" color="#3098d6" />
                  ) : (
                    <RiEyeLine size="22px" color="#3098d6" />
                  )}
                </EyeIconContainer>
              </InputGroup>

              <InputGroup>
                <Error name="full_name" component="div" />
                <InputIconContainer>
                  <FaUser size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть ПІБ"
                  type="text"
                  name="full_name"
                  disabled={isCurrentUser}
                />
              </InputGroup>

              <InputGroup>
                <Error name="id_job_title" component="div" />
                <InputIconContainer>
                  <FaBriefcase size="22" color="brown" />
                </InputIconContainer>
                <Select
                  as="select"
                  name="id_job_title"
                  disabled={isCurrentUser}
                >
                  <Option key="default-key1">Виберіть посаду:</Option>
                  {data?.map((title) => (
                    <Option value={title?.id} key={title?.id}>
                      {title?.name}
                    </Option>
                  ))}
                </Select>
              </InputGroup>

              <InputGroup>
                <Error name="telegram" component="div" />
                <InputIconContainer>
                  <FaTelegramPlane size="22" color="#0088CC" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть логін телеграму"
                  type="text"
                  name="telegram"
                />
              </InputGroup>

              <InputGroup>
                <Error name="card" component="div" />
                <InputIconContainer>
                  <FaCcMastercard size="28" color="#d6b330" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть номер картки"
                  type="text"
                  name="card"
                />
              </InputGroup>

              <InputGroup>
                <Error name="city" component="div" />
                <InputIconContainer>
                  <FaCity size="22" color="#0077B6" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть назву міста"
                  type="text"
                  name="city"
                />
              </InputGroup>

              <InputGroup>
                <Error name="address" component="div" />
                <InputIconContainer>
                  <FaAddressCard size="22" color="#0077B6" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть адресу"
                  type="text"
                  name="address"
                />
              </InputGroup>

              <InputGroup>
                <Error name="phone_number" component="div" />
                <InputIconContainer>
                  <FaPhoneAlt size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder="Введіть номер телефону"
                  type="tel"
                  name="phone_number"
                />
              </InputGroup>

              <InputGroup>
                <Error name="birthday" component="div" />
                <InputIconContainer>
                  <FaBirthdayCake size="22" color="green" />
                </InputIconContainer>
                <Input
                  placeholder="Виберіть дату народженя"
                  type="date"
                  name="birthday"
                />
              </InputGroup>

              {!isCurrentUser && (
                <>
                  <InputGroup>
                    <Error name="inviter_id" component="div" />
                    <InputIconContainer>
                      <FaUserTie size="22" color="brown" />
                    </InputIconContainer>
                    <Select
                      as="select"
                      name="inviter_id"
                      disabled={isCurrentUser}
                    >
                      <Option key="default-key">Хто привів:</Option>
                      {userData?.usersData?.map((user) => (
                        <Option value={user?.id} key={user?.id}>
                          {user?.full_name}
                        </Option>
                      ))}
                    </Select>
                  </InputGroup>

                  <InputGroup>
                    <Error name="education_day" component="div" />
                    <InputIconContainer>
                      <FaCalendarDays size="22" color="brown" />
                    </InputIconContainer>
                    <Input
                      placeholder="Оберіть дату навчання"
                      type="date"
                      name="education_day"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="iban" component="div" />
                    <InputIconContainer>
                      <CiBank size="22" color="#d6b330" />
                    </InputIconContainer>
                    <Input placeholder="Введіть IBAN" type="text" name="iban" />
                  </InputGroup>

                  <InputGroup>
                    <Error name="identification_number" component="div" />
                    <InputIconContainer>
                      <HiIdentification size="22" color="#d6b330" />
                    </InputIconContainer>
                    <Input
                      placeholder="Введіть ЄРДПОУ"
                      type="number"
                      name="identification_number"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="receiver_initial" component="div" />
                    <InputIconContainer>
                      <RiUserReceived2Fill size="22" color="#d6b330" />
                    </InputIconContainer>
                    <Input
                      placeholder="Отримувач зп"
                      type="text"
                      name="receiver_initial"
                    />
                  </InputGroup>

                  <InputGroup>
                    <Error name="employee_status_id" component="div" />
                    <InputIconContainer>
                      <RiGroup2Line  size="22" color="brown" />
                    </InputIconContainer>
                    <Select
                      as="select"
                      name="employee_status_id"
                      disabled={isCurrentUser}
                    >
                      <Option key="default-key">Статус оператора:</Option>
                      {employeeStatusesData?.data?.map((status) => (
                        <Option value={status?.id} key={status?.id}>
                          {status?.title}
                        </Option>
                      ))}
                    </Select>
                  </InputGroup>

                  <InputGroup>
                    <Error name="fired_status_id" component="div" />
                    <InputIconContainer>
                      <ImBlocked size="22" color="brown" />
                    </InputIconContainer>
                    <Select
                      as="select"
                      name="fired_status_id"
                      disabled={isCurrentUser}
                    >
                      <Option key="default-key">Причина блокування:</Option>
                      {firedEmployeeStatusesData?.data?.map((status) => (
                        <Option value={status?.id} key={status?.id}>
                          {status?.title}
                        </Option>
                      ))}
                    </Select>
                  </InputGroup>
                  <InputGroup>
                    <Error name="comment" component="div" />
                    <InputIconContainer>
                      <FaCommentDots size="22" color="#d6b330" />
                    </InputIconContainer>
                    <Input
                      placeholder="Введіть коментар"
                      type="text"
                      name="comment"
                    />
                  </InputGroup>
                </>
              )}
            </InputsList>
            <ButtonContainer>
              <Button type="submit">
                {isCreated ? "Створити користувача" : "Внести зміни"}
              </Button>
              {isCreated && (
                <TrashButton type="button" onClick={handleCancel}>
                  Скасувати
                </TrashButton>
              )}
              {isUpdate &&
                !isCurrentUser &&
                initialValues?.isBlocked === "true" && (
                  <Button type="button" onClick={handleBlock}>
                    Розблокувати користувача
                  </Button>
                )}
              {isUpdate &&
                !isCurrentUser &&
                initialValues?.isBlocked === "false" && (
                  <TrashButton type="button" onClick={handleBlock}>
                    Заблокувати користувача
                  </TrashButton>
                )}
            </ButtonContainer>
          </InfoForm>
        )}
      </Formik>
    </Main>
  );
};
