import React from "react";
import { Main } from "../../components/GlobalComponentsAndStyles/Main";
import { RoutePermissions } from "../../components/GlobalSettings/RouteAccess/RouteAccess";
import { MainRoutes } from "../../components/GlobalSettings/MainRoutes/MainRoutes";
import { ScrollToTopButton } from "../../components/ScrollToTopButton/ScrollToTopButton";

export const RouteSettings = () => {
  return (
    <div>
      <Main>
        <MainRoutes />
        <RoutePermissions />
        <ScrollToTopButton />
      </Main>
    </div>
  );
};
