import { useState, useEffect } from "react";
import { Td } from "../../GlobalComponentsAndStyles/GlobalStyles.styled";
import { fetchUsers } from "../../../api/users";

export const UserRow = ({ user }) => {
  const [inviterName, setInviterName] = useState("");

  useEffect(() => {
    const fetchInviter = async () => {
      if (user.inviter_id) {
        try {
          const getInviter = await fetchUsers({ id: user.inviter_id });
          if (getInviter?.usersData?.length > 0) {
            setInviterName(getInviter?.usersData[0]?.full_name || "");
          }
        } catch (error) {
          console.error("Error fetching inviter:", error);
          setInviterName("");
        }
      }
    };

    fetchInviter();
  }, [user.inviter_id]);

  return (
      <Td>{inviterName}</Td>
  );
};
