import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const deleteScheduleByDay = async ({ id }) => {
  try {
    const response = await makeRequestSQL.delete(`/schedule/`, {
      params: {
        id,
      },
    });

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchAllSchedule = async ({ year, month, isBlocked, jobTitle }) => {
  try {
    const response = await makeRequestSQL.get(`/schedule/`, {
      params: {
        year,
        month,
        isBlocked,
        jobTitle
      },
    });

    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const updateSchedule = async (data) => {
  try {
    const response = await makeRequestSQL.put(`/schedule/`, data);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const createScedule = async (scheduleData) => {
  try {
    const response = await makeRequestSQL.post("/schedule", scheduleData);
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchMySchedule = async ({ year, month }) => {
  try {
    const response = await makeRequestSQL.get("/schedule/me", {
      params: {
        year,
        month,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
