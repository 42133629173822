import { makeRequestSQL } from "./api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decryptMiddleware } from "../services/decryptMiddleware";

export const fetchDailyStatistic = async ({
  userId,
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/daily", {
      params: {
        userId,
        sortBy,
        order,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchSummaryByEmployee = async ({
  userId,
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/summary-by-employee", {
      params: {
        userId,
        sortBy,
        order,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOverallSummary = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/overall-summary", {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchOverallSummaryDaily = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/overall-summary-daily", {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchProductOrderStatistics = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/product", {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchContactsStatistics = async ({
  sortBy,
  order,
  startDate,
  endDate,
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/contact", {
      params: {
        sortBy,
        order,
        startDate,
        endDate,
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};

export const fetchNewsStatistics= async ({
  sortBy,
  order,
  startDate,
  endDate
}) => {
  try {
    const response = await makeRequestSQL.get("/statistics/news", {
      params: {
        sortBy,
        order,
        startDate,
        endDate
      },
    });
    const decryptedData = await decryptMiddleware(response.data);

    return decryptedData.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
  }
};
